<template>
  <div class="law-menu">
    <ul v-for="(item, index) of catalog" :key="'catalog-' + index">
      <Tree :data="item" />
    </ul>
  </div>
</template>
<script>
import Tree from './tree'
export default {
  name: 'law-menu',
  props: ['catalog'],
  components: {
    Tree
  }
}
</script>

<style lang="stylus" scoped>
.law-menu
  width 80vw
  padding 5px
</style>
