<template>
  <div class="law-scroller">
    <virtual-list class="scroller" @scroll="handleScroll" :data-key="'id'" :extra-props="{ isCleanMode }" :data-sources="dataList" :data-component="itemComponent" ref="vlRef">
      <template slot="header">
        <div class="mode-text" @click="emitToggleMode">
          {{ modeText }}
        </div>
      </template>
      <template slot="footer">
        <bottom-tip :visible="bottomTipVisible" :showLimit.sync="showLimit" />
      </template>
    </virtual-list>
    <!-- <div class="normal-list-container">
      <law-block
        :isCleanMode="isCleanMode"
        v-for="(item, i) in dataList"
        :key="item.id + i"
        :source="item"
      />
    </div> -->

    <van-popup v-model="additionPopupVisible" close-icon-position="top-left" get-container="#outter" position="bottom" :style="{ height: '70%' }">
      <law-addition :addition_data="additions" :id="additionId" @popLaw="emitPopLaw" />
    </van-popup>
    <van-popup v-model="menuVisible" get-container="#outter" position="right" :style="{ height: '100%' }">
      <law-menu :catalog="catalog" />
    </van-popup>
    <fb-nonmember :showLimit.sync="showLimit" />

    <div class="float-actions">
      <div class="menu" @click="toggleMenu">
        <i class="iconfont icon-liebiao1"></i>
      </div>
      <div class="collect" @click="emitCollect" v-if="reportCount > 0">
        <transition name="slide-fade">
          <span class="badge-box" v-show="showAdd"> +1 </span>
        </transition>
        <transition name="slide-fade">
          <span class="badge-box" v-show="showReduce"> -1 </span>
        </transition>
        <span class="collect-text">集</span>
        <span class="count">{{ this.reportCount }}</span>
      </div>
      <div class="query" @click="emitOpenInnerQuery">
        <i class="iconfont icon-search"></i>
      </div>
      <div class="to-top" @click="scrollToTop">
        <i class="iconfont icon-cc-top"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from '@/mixins/emitter'
// import { scrollTop } from '@/utils/assist'
import VirtualList from 'vue-virtual-scroll-list'
import LawBlock from './components/law-block'
import LawMenu from './components/law-menu'
import LawAddition from './components/law-block/law-addition'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'law-scroller',
  props: {
    // 列表数据源
    dataList: {
      type: Array,
      default: (_) => [],
    },
    // 目录
    catalog: {
      type: Array,
    },
    // 大量数据（使用虚拟滚动）
    isBig: {
      type: Boolean,
      default: (_) => false,
    },
    // 简洁模式
    isCleanMode: {
      type: Boolean,
      default: (_) => false,
    },
    // 跳转到法条
    toCatalog: {
      type: String,
    },
  },
  components: {
    VirtualList,
    // LawBlock,
    LawAddition,
    LawMenu,
  },
  data() {
    return {
      showLimit: false,
      itemComponent: LawBlock,
      additionPopupVisible: false,
      additionId: '',
      additions: [],
      menuVisible: false,
      showAdd: false, // 是否显示+1
      showReduce: false, // 是否显示-1
      lastCount: 0, // 检索报告的数值
      showReport: false,
    }
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters('searchReport', ['reportCount']),
    ...mapState('searchReport', ['contentReports']),
    ...mapState('user', ['userInfo', 'userinfo']),
    ...mapState('nonmemberLimit', ['isValid']),
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    bottomTipVisible() {
      // if (this.isValid) {
      //   return false
      // }
      // return (
      //   this.userInfo && this.userInfo.memberStatus !== 1 && this.userInfo.restNum <= 0
      // )

      return this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !this.$route.query.isMiniApp
    },
    modeText() {
      return this.isCleanMode ? '显示扩展' : '只看原文'
    },
  },
  methods: {
    // 监听滚动到底部时显示弹窗
    handleScroll(e) {
      const flag = (e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight
      let showLimit = false
      if (this.userId || this.$route.query.id) {
        if (flag && this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !this.$route.query.isMiniVip) {
          console.log('登陆非会员滚动到底部')
          showLimit = true
        } else {
          showLimit = false
        }
      } else {
        showLimit = false
      }
      this.showLimit = showLimit
      // this.$emit('scrollBottom', showLimit)
    },
    // 切换显示目录
    toggleMenu() {
      this.menuVisible = true
    },
    emitCollect() {
      this.$emit('collect')
    },
    // 回到顶部
    scrollToTop() {
      this.$refs.vlRef && this.$refs.vlRef.scrollToOffset(0)
    },
    /**
     * 滚动至指定下标
     * @param index{Number} 元素下标
     */
    scrollToIndex(index) {
      this.$nextTick(() => {
        this.$refs.vlRef && this.$refs.vlRef.scrollToIndex(index)
      })
    },
    scrollToBottom() {
      this.$refs.vlRef && this.$refs.vlRef.scrollToBottom()
    },
    totop() {
      console.log('reach totop')
    },
    tobottom() {
      console.log('reach tobottom')
    },
    // 监听灰色区域展开关闭事件
    listenAdditionShowEvent() {
      this.$on('on-law-addition-show', (payload) => {
        this.$emit('additionShowEvent', payload)
      })
    },
    listenAdditionContentShowEvent() {
      this.$on('on-law-addition-content-show', (payload) => {
        this.$emit('additionContentShowEvent', payload)
      })
    },
    // 鼠标移入移出事件
    listenMouseMoveEvent() {
      this.$on('on-law-addition-mouse-move', (payload) => {
        this.$emit('additionMouseMoveEvent', payload)
      })
    },
    // 曲别针点击事件
    listenClickClipDataEvent() {
      this.$on('on-show-law-clip-data', (payload) => {
        this.$emit('clickClipDataEvent', payload)
      })
    },
    // 右侧数字点击事件
    listenQuoteClickEvent() {
      this.$on('on-show-law-quote', (payload) => {
        this.$emit('quoteClickEvent', payload)
      })
    },
    // 翻页
    listenAdditionPageNextEvent() {
      this.$on('on-law-addition-page-next', (payload) => {
        this.$emit('additionPageNextEvent', payload)
      })
    },
    // 选择目录 跳转至指定条
    onCatalogSelect({ name }) {
      const blockIndex = this.dataList.findIndex((item) => {
        /**
         * 条的名称可能为 第n条【xxxx】
         * !(数据中间无空格)
         * 我们只需要匹配 name === 第n条
         * 故将 第n条【xxxx】转为数组取第一个元素进行对比即可
         */
        return (item.name && (item.name === name || item.name.startsWith(name) || name.startsWith(item.name))) || item.name.indexOf(name) !== -1
      })
      if (blockIndex && blockIndex > -1) {
        this.scrollToIndex(blockIndex)
      }
      this.menuVisible = false
    },
    // 事件监听
    listenEvents() {
      // 目录选中
      this.$on('on-catalog-select', this.onCatalogSelect)

      // 法律文本勾选
      this.$on('on-law-check', (payload) => {
        this.$emit('onLawCheck', payload)
      })

      this.$on('on-copy-block', (payload) => {
        this.$emit('onCopyBlock', payload)
      })

      // 打开 关联法条
      this.$on('on-open-addition', ({ id, additions }) => {
        this.additionId = id
        this.additions = additions
        this.additionPopupVisible = true
      })
    },
    // 跳转到指定条
    scrollToCatalog(name) {
      if (!name) return
      // TODO 不清楚为什么该虚拟滚动组件在跳转的时候可能不正确 暂时通过跳转两次解决
      setTimeout(() => {
        this.onCatalogSelect({ name })
      }, 800)
      setTimeout(() => {
        this.onCatalogSelect({ name })
      }, 900)
    },
    // 传递打开法律弹窗事件
    emitPopLaw(payload) {
      this.$emit('popLaw', payload)
    },
    // 传递打开页内检索
    emitOpenInnerQuery() {
      this.$emit('openInnerQuery')
    },
    // 传递模式切换
    emitToggleMode() {
      this.$emit('toggleMode')
    },
  },
  mounted() {
    // 非会员默认弹窗
    if (this.$store.state.user.userinfo.id) {
      const { isMiniVip } = this.$route.query
      if (this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !isMiniVip) {
        this.showLimit = true
      } else {
        this.showLimit = false
      }
    } else {
      this.showLimit = false
    }
    this.scrollToCatalog(this.toCatalog)
  },
  watch: {
    reportCount(nv, ov) {
      this.lastCount = ov
      if (nv === ov + 1) {
        this.showAdd = true
        setTimeout(() => {
          this.showAdd = false
        }, 600)
      } else if (nv === ov - 1) {
        this.showReduce = true
        setTimeout(() => {
          this.showReduce = false
        }, 600)
      }
    },
  },
  created() {
    this.listenAdditionPageNextEvent()
    this.listenEvents()
    this.listenQuoteClickEvent()
    this.listenClickClipDataEvent()
    this.listenAdditionShowEvent()
    this.listenAdditionContentShowEvent()
  },
}
</script>

<style scoped lang="stylus">
.law-scroller
  position relative
  height 100%
  width 100vw
  .scroller
    height 100%
    width 100vw
    // padding-bottom 66px
    overflow-x hidden
    overflow-y auto
    & .mode-text
      width 80px
      height 32px
      text-align center
      background-color #ff9a01
      color #fff
      line-height 32px
      font-size 16px
  .normal-list-container
    width 100%
    margin 0 auto
    border-radius 4px
    background #fff
  & .float-actions
    position fixed
    display flex
    flex-direction column
    width 44px
    right 30px
    bottom 30px
    & .menu, & .to-top, & .query, & .collect
      width 44px
      height 44px
      border-radius 50%
      display flex
      align-items center
      justify-content center
      background #b6b6b6
      text-align center
      line-height 1.2rem
      margin-bottom 10px
      color #ffffff
      font-size 0.64rem
    & .collect
        position relative
      & .count
        font-size 16px
        line-height 16px
        position absolute
        color red
        right 3px
        top 3px
      & .badge-box
        position absolute
        top -10px
        right 0
        text-align right
        color red !important
        z-index 999
        font-weight bold !important
        font-size 14px !important
      &::-webkit-scrollbar
        display none
      & .count
        font-size 16px
        line-height 16px
        position absolute
        color red
        right 3px
        top 3px
      & .collect-text
          font-size 20px
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate(-30px, -10px) scale(3);
  opacity: 0;
}
</style>
