var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "after-tip" },
    _vm._l(_vm.icons, function (act) {
      return _c("i", {
        key: act.name,
        staticClass: "iconfont",
        class: `icon-${act.icon}`,
        attrs: { title: act.label },
        on: {
          click: function ($event) {
            return _vm.onIconClick(act.name)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }