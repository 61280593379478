<template>
  <div class="law-addition" v-if="addition_data && addition_data.length > 0">
    <div class="addition-list">
      <template v-for="(item, index) in addition_data">
        <div
          class="addition-box"
          v-if="item.values.length > 0"
          :key="item.title + index"
        >
          <div class="addition-title">
            <span class="addition-title-span">{{ item.title }}</span>
            <!-- <i
              class="el-icon-caret-bottom"
              v-show="!item.isHidden"
              @click="showContent(index, true)"
            ></i>
            <i
              class="el-icon-caret-right"
              v-show="item.isHidden"
              @click="showContent(index, false)"
            ></i> -->
          </div>
          <div class="addition-content" v-show="!item.isHidden">
            <div
              class="addition-content-box"
              v-for="(childItem, i) in item.values"
              :key="item.title + '-c2-' + i"
            >
              <div v-show="i + 1 <= item.page * 10 || item.page === undefined">
                <div
                  class="addition-content-title"
                  v-if="
                    item.title === '经典案例' ||
                      item.title === '典型案例' ||
                      item.title === '公报案例' ||
                      item.title === '指导案例' ||
                      item.title === '参阅案例'
                  "
                >
                  <span class="addition-can-hover">
                    {{ childItem.case_name }}
                    <span class="addition-content-text-btn">
                      <i
                        class="iconfont icon-fuzhi1"
                        title="复制本段内容"
                        @click="copy(childItem.case_name)"
                      ></i>
                      <i
                        class="iconfont icon-send"
                        @click="toDetail(childItem)"
                      ></i>
                    </span>
                  </span>
                </div>
                <div class="addition-content-title" v-else>
                  <span class="addition-can-hover">
                    <div
                      class="check-box"
                      :class="childItem.checked ? 'checked' : 'unchecked'"
                      @click.stop=""
                    >
                      <van-checkbox
                        v-model="childItem.checked"
                        icon-size="24px"
                        shape="square"
                        @change="handleCheck($event, childItem)"
                      ></van-checkbox>
                    </div>
                    <span
                      style="color: red;margin-right: 10px"
                      v-if="
                        childItem.timeliness &&
                          childItem.timeliness !== '现行有效'
                      "
                      >{{ childItem.timeliness }}</span
                    >{{ childItem.law_title }}
                    <span
                      class="addition-content-text-btn"
                      style="width: 100px; font-weight: normal"
                    >
                      <i
                        class="iconfont icon-fuzhi1"
                        title="复制本段内容"
                        @click="copy(childItem.law_title)"
                      ></i>
                      <i
                        v-if="
                          item.title === '关联法条' || item.title === '司法解释'
                        "
                        class="iconfont icon-copy-block"
                        title="复制本条全部内容"
                        @click="copyBlock(childItem)"
                      ></i>
                      <i
                        class="iconfont icon-send"
                        @click="toDetail(childItem)"
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- 详情 -->
              <div
                v-for="(childItem2, i2) in childItem.child"
                :key="item.title + '-c1-' + i2"
              >
                <lawAdditionText
                  :childItem2="childItem2"
                  :childItem="childItem"
                  :checkedAll="childItem.checked"
                  :item="item"
                  @copy="copy"
                  @copyBlockOne="copyBlockOne"
                />
              </div>
            </div>
            <div
              class="addition-content-box"
              v-if="item.page && item.values.length > item.page * 10"
            >
              <div
                class="addition-content-title"
                style="color: #f90"
                @click="next(index)"
              >
                更多
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Emitter from '@/mixins/emitter'
import Mixin from './mixins'
import lawAdditionText from './law-addition-text'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'law-addition',
  props: {
    id: {
      type: String,
    },
    addition_data: {
      type: Array,
      default: _ => [],
    },
  },
  mixins: [Emitter, Mixin],
  components: { lawAdditionText },
  data() {
    return {}
  },
  computed: {
    ...mapState('searchReport', ['contentReports']),
  },
  methods: {
    ...mapActions('searchReport', ['setStampReport']),
    ...mapMutations('searchReport', ['SET_CONTENT_REPORT']),
    handleCheck(flag, childItem) {
      this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
      // console.log(flag, childItem, this.contentArr, '====>>> item,childItem')
      let contentObj = {}
      const currentArr = childItem.child.map(v => {
        return { name: v.title, title: v.title, text: v.text, checked: true }
      })
      if (this.contentArr.length > 0) {
        const index = this.contentArr.findIndex(
          item => item.id === childItem.law_id
        )
        if (index === -1) {
          contentObj = {
            id: childItem.law_id,
            title: childItem.law_title || '',
            isLaw: true,
            arr: currentArr,
            textArr: [],
          }
          if (flag) {
            this.contentArr.push(contentObj)
          }
        } else {
          contentObj = this.contentArr[index]
          const oldNameArr = contentObj.arr.map(v => v.name)
          let hasRepeat = false
          currentArr.forEach(v => {
            const innerIndex = oldNameArr.indexOf(v.name)
            console.log(innerIndex)
            if (innerIndex === -1) {
              if (flag) {
                // 去重添加
                this.contentArr[index].arr.push(v)
              }
            } else {
              hasRepeat = true
              if (!flag) {
                // 删除存在的
                this.contentArr[index].arr.splice(innerIndex)
              }
            }
          })
          if (hasRepeat && flag) {
            console.log('有重复收集的内容,去重添加')
          }
        }
      } else {
        contentObj = {
          id: childItem.law_id,
          title: childItem.law_title || '',
          isLaw: true,
          arr: currentArr,
          textArr: [],
        }
        if (flag) {
          this.contentArr.push(contentObj)
        }
      }
      // console.log(this.contentArr)
      if (this.$store.state.user.userInfo.id) {
        this.setStampReport({
          userId: this.$store.state.user.userInfo.id,
          type: 'contentReports',
          reportData: JSON.parse(JSON.stringify(this.contentArr)) || [],
        })
      } else {
        this.SET_CONTENT_REPORT(
          JSON.parse(JSON.stringify(this.contentArr)) || []
        )
      }
    },
    next(index) {
      // this.addition_data[index].page++
      const payload = {
        index: index,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-page-next', payload)
    },
    // 显示隐藏
    showContent(index, flag) {
      const payload = {
        index: index,
        flag: flag,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-content-show', payload)
    },
    // 黑色加粗标题的移入移出
    mouseenterLawTitle(index, i) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        attributeName: 'law_title_copy',
        flag: true,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    mouseleaveLawTitle(index, i) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        attributeName: 'law_title_copy',
        flag: false,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    // 黑色不加粗标题的移入移出
    mouseenterChildTitle(index, i, i2) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        additionDataValuesChildIndex: i2,
        attributeName: 'title_copy',
        flag: true,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    mouseleaveChildTitle(index, i, i2) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        additionDataValuesChildIndex: i2,
        attributeName: 'title_copy',
        flag: false,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    // 正文的移入移出
    mouseenterChildText(index, i, i2) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        additionDataValuesChildIndex: i2,
        attributeName: 'text_copy',
        flag: true,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    mouseleaveChildText(index, i, i2) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        additionDataValuesChildIndex: i2,
        attributeName: 'text_copy',
        flag: false,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
    },
    copyBlock(childItem) {
      let str = ''
      str += childItem.law_title + '\n'
      if (childItem.child) {
        childItem.child.forEach(item => {
          str += item.title + '\n'
          str += item.text + '\n'
        })
      }
      this.copy(str)
    },
    copyBlockOne(childItem, childItem2) {
      let str = ''
      str += childItem.law_title + '\n'
      str += childItem2.title + '\n'
      str += childItem2.text + '\n'
      this.copy(str)
    },
    // 复制事件
    copy(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({ type: 'success', message: '内容已复制' })
    },
    // 详情
    toDetail(item, title = '') {
      if (item.doc) {
        // 跳转打开判例详情
        const url = `${document.location.origin}/search?docid=${item.doc}`
        window.open(url)
      } else if (item.law_id) {
        // 跳转打开法律详情,滚动到对应的条目
        this.$emit('popLaw', { id: item.law_id, toCatalog: title || '' })
      } else {
        // 既没有doc又没有law_id的，表示是当前法律
        if (item.title) {
          // 滚动到当前条目
          this.broadcast('law-scroller', 'on-catalog-select', {
            name: item.title,
            type: '条',
          })
        }
      }
    },
    more(flag, index, i, i2) {
      const payload = {
        additionDataIndex: index,
        additionDataValuesIndex: i,
        additionDataValuesChildIndex: i2,
        attributeName: 'textMore',
        flag: flag,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-mouse-move', payload)
      // this.addition_data[index].values[i].child[i2].textMore = flag
    },
  },
  mounted() {},
  created() {
    this.addition_data.forEach(item => {
      if (item.title !== '司法解释' && item.title !== '关联法条') {
        item.page = 1
      }
    })
  },
}
</script>

<style lang="stylus" scoped>
.law-addition
  position relative
  width 100%
  height 100%
  overflow-x hidden
  background-color rgba(242, 242, 242, 1)
  & .law-addition-show-icon
    font-size 20px
    position absolute
    top -30px
    right -30px
    color #f90
    cursor pointer
  & .addition-list
    padding 10px 20px
    box-sizing border-box
    & .addition-box
      & .addition-title
        & .addition-title-span
          color #FF9900
          font-size 12px
        & > i
          color #333
          cursor pointer
      & .addition-content
        & .addition-content-box
          position relative
          padding 0 25px
          box-sizing border-box
          & .addition-content-title
            color #000
            font-size 12px
            font-weight bold
            margin-bottom 5px
            position relative
            & .addition-can-hover
              position relative
              & .check-box
                display inline-block
                position absolute
                width 20px
                left -20px
                display flex
                justify-content center
                visibility hidden
                &.checked
                  visibility visible
              & .addition-content-text-btn
                position absolute
                width 60px
                font-weight normal
                visibility hidden
                & i
                  color rgb(86, 154, 249)
                  cursor pointer
                  margin-left 10px
                  font-size 14px
              &:hover, &.hover
                & .addition-content-text-btn
                  visibility visible
                & .check-box
                  visibility visible
                  &.checked
                    visibility visible
                  &.unchecked
                    visibility visible
          & .addition-content-text
            font-size 14px
            color #666666
            margin-bottom 5px
          & .addition-content-text-div
            line-height 18px
            position relative
            & .addition-can-hover
              position relative
              & .addition-content-text-btn
                position absolute
                width 60px
                font-weight normal
                visibility hidden
                & i
                  color rgb(86, 154, 249)
                  cursor pointer
                  margin-left 10px
                  font-size 14px
              &:hover, &.hover
                & .addition-content-text-btn
                  visibility visible
          & .addition-content-text-div-more
            position absolute
            bottom 0px
            right 0px
            background #f2f2f2
            width 47px
            & > i
              cursor pointer
              color #409EFF
              font-size 14px
</style>
