<template>
  <div class="addition-content-text">
    <div v-if="childItem2.title" class="addition-content-title">
      <span class="addition-can-hover">
        <div class="check-box" :class="checked ? 'checked' : 'unchecked'" @click.stop="">
          <van-checkbox v-model="checked" icon-size="24px" shape="square" @change="handleCheckChild($event, childItem)"></van-checkbox>
        </div>
        {{ childItem2.title }}
        <span class="addition-content-text-btn" style="width: 100px">
          <i class="iconfont icon-fuzhi1" title="复制本段内容" @click="copy(childItem2.title)"></i>
          <!-- <i title="复制本条全部内容" class="iconfont icon-copy-block" @click="copyBlockOne(childItem, childItem2)"></i> -->
          <i class="iconfont icon-send" @click="toDetail(childItem, childItem2.title)"></i>
        </span>
      </span>
    </div>
    <div v-if="childItem2.text" class="addition-content-text-div">
      <span class="addition-can-hover">
        <span v-html="setHtml(childItem2.text)"></span>
        <span class="addition-content-text-btn">
          <i title="复制本段内容" class="iconfont icon-fuzhi1" @click.stop="copy(childItem2.text)"></i>
          <i class="iconfont icon-send" @click.stop="toDetail(childItem, childItem2.title)"></i>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: ['childItem2', 'childItem', 'item', 'checkedAll'],
  data() {
    return {
      checked: false,
      contentArr: [],
      checkedAllChange: false,
    }
  },
  computed: {
    ...mapState('searchReport', ['contentReports']),
  },
  methods: {
    ...mapActions('searchReport', ['setStampReport']),
    ...mapMutations('searchReport', ['SET_CONTENT_REPORT']),
    handleCheckChild(flag) {
      if (this.checkedAllChange) return
      console.log('========>>>handleCheckChild')
      this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
      console.log(flag, this.childItem2, this.childItem)
      const lawObjIndex = this.contentArr.findIndex((item) => {
        return item.id === this.childItem.law_id
      })
      let lawObj = null
      const arrObj = {
        name: this.childItem2.title,
        title: this.childItem2.title,
        text: this.childItem2.text,
      }
      if (lawObjIndex !== -1) {
        lawObj = this.contentArr[lawObjIndex]
        const oldNameArr = lawObj.arr.map((v) => v.name)
        const innerIndex = oldNameArr.indexOf(arrObj.name)
        if (flag) {
          // 添加
          if (innerIndex === -1) {
            this.contentArr[lawObjIndex].arr.push(arrObj)
          } else {
            return this.$notify('您已收集过相同的内容')
          }
        } else {
          if (innerIndex !== -1) {
            this.contentArr[lawObjIndex].arr.splice(innerIndex, 1)
          }
        }
      } else {
        if (flag) {
          lawObj = {
            id: this.childItem.law_id,
            title: this.childItem.law_title || '',
            isLaw: true,
            arr: [arrObj],
            textArr: [],
          }
          this.contentArr.push(lawObj)
        }
      }
      if (this.$store.state.user.userInfo.id) {
        this.setStampReport({
          userId: this.$store.state.user.userInfo.id,
          type: 'contentReports',
          reportData: JSON.parse(JSON.stringify(this.contentArr)) || [],
        })
      } else {
        this.SET_CONTENT_REPORT(JSON.parse(JSON.stringify(this.contentArr)) || [])
      }
    },
    checkcnnum(str) {
      var res = 1
      const zh = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿']
      for (let i = str.length - 1; i >= 0; i--) {
        // 循环对比
        var ares = 0
        for (var j in zh) {
          if (zh[j] === str[i]) {
            ares = 1
          }
        }
        // 根据对比结果判断
        if (ares === 0) {
          res = 0
          return res
        }
      }
      return res
    },
    fliterText(text) {
      const list = text.split('条')
      let str = ''
      list.forEach((item) => {
        const index = item.indexOf('第', item.length - 8)
        if (index > -1) {
          if (this.checkcnnum(item.slice(index + 1)) === 1) {
            str += item.slice(0, index) + "<span style='opacity: 0.5;'>" + item.slice(index) + '条' + '</span>'
          } else {
            str += item + '条'
          }
        } else {
          str += item + '条'
        }
      })
      str = str.slice(0, str.length - 1)
      return str
    },
    setHtml(text) {
      let str = text
      const startIndex = text.indexOf('【')
      const endIndex = text.indexOf('】')
      if (startIndex > -1 && endIndex > -1) {
        str = text.slice(0, startIndex + 1) + "<span style='opacity: 0.5;'>" + text.slice(startIndex + 1, endIndex) + '</span>' + text.slice(endIndex)
      }

      str = this.fliterText(str)
      return str
    },
    copy(text) {
      this.$emit('copy', text)
    },
    copyBlockOne(childItem, childItem2) {
      this.$emit('copyBlockOne', childItem, childItem2)
    },
    toDetail(item, title) {
      this.$emit('toDetail', item, title)
    },
  },
  watch: {
    checkedAll() {
      this.checked = this.checkedAll
      this.checkedAllChange = true
      this.$nextTick(() => {
        this.checkedAllChange = false
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.addition-content-title
  color #000
  font-size 12px
  font-weight bold
  margin-bottom 5px
  position relative
  & .addition-can-hover
    position relative
    & .check-box
      display inline-block
      position absolute
      width 20px
      left -20px
      display flex
      justify-content center
      visibility hidden
      &.checked
        visibility visible
    & .addition-content-text-btn
      position absolute
      width 60px
      font-weight normal
      visibility hidden
      & i
        color rgb(86, 154, 249)
        cursor pointer
        margin-left 10px
        font-size 14px
    &:hover, &.hover
      & .addition-content-text-btn
        visibility visible
      & .check-box
        visibility visible
        &.checked
          visibility visible
        &.unchecked
          visibility visible
.addition-content-text
  font-size 14px
  color #666666
  margin-bottom 5px
.addition-content-text-div
  line-height 18px
  position relative
  & .addition-can-hover
    position relative
    & .addition-content-text-btn
      position absolute
      width 60px
      font-weight normal
      visibility hidden
      & i
        color rgb(86, 154, 249)
        cursor pointer
        margin-left 10px
        font-size 14px
    &:hover, &.hover
      & .addition-content-text-btn
        visibility visible
      & .check-box
        visibility visible
        &.checked
          visibility visible
        &.unchecked
          visibility visible
.addition-content-text-div-more
  position absolute
  bottom 0px
  right 0px
  background #f2f2f2
  width 47px
  & > i
    cursor pointer
    color #409EFF
    font-size 14px
</style>
