var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("van-nav-bar", {
    attrs: { title: _vm.title, "left-arrow": "" },
    on: {
      "click-left": function ($event) {
        return _vm.emitEvent("BACK")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "right",
        fn: function () {
          return [
            _c("van-popover", {
              attrs: {
                trigger: "click",
                theme: "dark",
                placement: "bottom-end",
                actions: _vm.actions,
              },
              on: { select: _vm.onSelect },
              scopedSlots: _vm._u([
                {
                  key: "reference",
                  fn: function () {
                    return [_c("i", { staticClass: "iconfont icon-caozuo" })]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.showPopover,
                callback: function ($$v) {
                  _vm.showPopover = $$v
                },
                expression: "showPopover",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }