<template>
  <p class="law-text" :data-id="id" :data-index="index">
    <!-- <span @mousedown="onCheck" :style="cleanModeVisibleStyle">
      <i class="fake-check-box" :class="checkBoxClassName"></i
    ></span> -->
    <span class="text-container">
      <span v-html="htmlString"></span>
      <after-tip :source="afterTipData" />
    </span>
  </p>
</template>

<script>
import Emitter from '@/mixins/emitter'
import Mixin from './mixins'

import AfterTip from '../after-tip'

export default {
  name: 'law-text',
  props: {
    index: {
      type: Number,
    },
    item: {
      type: Object,
      default: _ => '',
    },
    isCleanMode: {
      type: Boolean,
    },
  },
  inject: ['id'],
  mixins: [Emitter, Mixin],
  components: { AfterTip },
  data() {
    return {}
  },
  computed: {
    afterTipData() {
      return { text: this.item.text }
    },
    checked() {
      return !!this.item.checked
    },
    // 文本内容可能包含 html 标签
    htmlString({ item }) {
      return item.$text || item.text
    },
  },
  methods: {
    onCheck() {
      // 通过 mixin/emitter 的 dispatch 方法向 law-scroller 发送消息
      const text = this.htmlString.replace(/<[^>]+>/g, '')
      const payload = {
        id: this.id,
        type: 'TEXT',
        text,
        index: this.index,
        checked: this.checked,
      }
      this.dispatch('law-scroller', 'on-law-check', payload)
    },
  },
  mounted() {},
  created() {},
}
</script>

<style lang="stylus" scoped>
.law-text
  line-height 28px
  font-size 18px
</style>
