<template>
  <div class="attrs">
    <span class="attr" v-for="a in attr" :key="a.name">{{ fmtLabel(a) }}</span>
  </div>
</template>

<script>
export default {
  name: 'law-attr',
  props: {
    attr: {
      type: Array,
      default: _ => []
    }
  },
  data() {
    return {}
  },
  methods: {
    // 格式化属性文字
    fmtLabel(attr) {
      if (attr.name === '发布日期') {
        return `${attr.value}发布`
      } else if (attr.name === '实施日期') {
        return `${attr.value}实施`
      } else {
        return attr.value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.attrs
  text-align center
  color #666
  font-size 15px
  margin 10px 0
  padding-bottom 10px
  border-bottom 1px solid #e60012
  & .attr
    padding-right 10px
</style>
