var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-detail" },
    [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _c("nav-bar", {
            attrs: { title: _vm.lawName },
            on: { menuEvent: _vm.handleNavbarEvent },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content", class: { peek: _vm.isPeeking } },
        [
          _c(
            "van-loading",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              attrs: { color: "#1989fa", vertical: "" },
            },
            [_vm._v("加载中...")]
          ),
          _vm.isError
            ? _c(
                "div",
                { staticClass: "error" },
                [
                  _c(
                    "van-empty",
                    { attrs: { image: "network", description: "接口错误" } },
                    [
                      _c(
                        "van-button",
                        {
                          staticClass: "bottom-button",
                          attrs: { round: "", type: "info" },
                          on: { click: _vm.retryFetchLaw },
                        },
                        [_vm._v("点击重试")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dataList.length && !_vm.isError
            ? _c("law-scroller", {
                ref: "lawScrollerRef",
                attrs: {
                  id: "lawDetailWrapper",
                  isCleanMode: _vm.isCleanMode,
                  toCatalog: _vm.toCatalog,
                  catalog: _vm.catalog,
                  dataList: _vm.dataList,
                },
                on: {
                  onLawCheck: _vm.onLawCheck,
                  collect: _vm.handleCollect,
                  popLaw: _vm.onPopLaw,
                  clickClipDataEvent: _vm.clickClipDataEvent,
                  quoteClickEvent: _vm.quoteClickEvent,
                  onCopyBlock: _vm.onCopyBlock,
                  openInnerQuery: _vm.openInnerQueryPopup,
                  toggleMode: function ($event) {
                    return _vm.handleNavbarEvent("TOGGLE")
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPeeking,
                  expression: "isPeeking",
                },
              ],
              staticClass: "kw-panel",
            },
            [
              _c(
                "div",
                { staticClass: "kw-list" },
                _vm._l(_vm.kwList, function (kw) {
                  return _c(
                    "van-tag",
                    {
                      key: kw,
                      staticClass: "kw-tag",
                      attrs: {
                        plain: !_vm.isNavKw(kw),
                        size: "large",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleUse(kw)
                        },
                      },
                    },
                    [_vm._v(_vm._s(kw))]
                  )
                }),
                1
              ),
              _c("div", { staticClass: "kw-nav" }, [
                _c("div", { staticClass: "act" }, [
                  _c(
                    "div",
                    { staticClass: "quit", on: { click: _vm.exitPeeking } },
                    [_vm._v("退出")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "edit",
                      on: { click: _vm.openInnerQueryPopup },
                    },
                    [_vm._v("编辑")]
                  ),
                ]),
                _c("div", { staticClass: "posi" }, [
                  _vm._v(
                    _vm._s(_vm.hlCurrentNum) + " / " + _vm._s(_vm.hlTotalNum)
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "nav" },
                  [
                    _c("van-icon", {
                      attrs: { name: "arrow-up" },
                      on: {
                        click: function ($event) {
                          return _vm.navKw("up")
                        },
                      },
                    }),
                    _c("van-icon", {
                      attrs: { name: "arrow-down" },
                      on: {
                        click: function ($event) {
                          return _vm.navKw("down")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm.showReport
            ? _c(
                "div",
                { staticClass: "collect-report" },
                [
                  _c("searchReport", {
                    attrs: { from: "law" },
                    on: { close: _vm.closeReport },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.wangpan
            ? _c(
                "div",
                { staticClass: "skyDrive" },
                [
                  _c("skyDrive", {
                    attrs: {
                      listid: _vm.id,
                      Type: "lawid",
                      listname: _vm.lawName,
                    },
                    on: { CancelCollect: _vm.close, getId: _vm.getId },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%" },
          attrs: { "get-container": "#outter", position: "right" },
          model: {
            value: _vm.queryPopupVisible,
            callback: function ($$v) {
              _vm.queryPopupVisible = $$v
            },
            expression: "queryPopupVisible",
          },
        },
        [
          _c("inner-query", {
            ref: "queryRef",
            class: { peek: _vm.isPeeking },
            attrs: { resultList: _vm.hlResult },
            on: {
              cancel: _vm.cancelInnerQueryPopup,
              view: _vm.viewHighlight,
              highlight: _vm.handleInnerQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "86%" },
          attrs: { "get-container": "#outter", position: "bottom" },
          model: {
            value: _vm.lawPopupVisible,
            callback: function ($$v) {
              _vm.lawPopupVisible = $$v
            },
            expression: "lawPopupVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pop-content" },
            [
              _c(
                "van-loading",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lawPopupLoading,
                      expression: "lawPopupLoading",
                    },
                  ],
                  attrs: { color: "#1989fa", vertical: "" },
                },
                [_vm._v("加载中...")]
              ),
              _vm.lawPopupDataList.length
                ? _c("law-scroller", {
                    attrs: {
                      isCleanMode: true,
                      toCatalog: _vm.lawPopupToCatalog,
                      catalog: _vm.lawPopupCatalog,
                      dataList: _vm.lawPopupDataList,
                    },
                    on: {
                      popLaw: _vm.onPopLaw,
                      clickClipDataEvent: _vm.clickClipDataEvent,
                      onCopyBlock: _vm.onCopyBlock,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "70%" },
          attrs: { position: "left" },
          model: {
            value: _vm.clipDataShow,
            callback: function ($$v) {
              _vm.clipDataShow = $$v
            },
            expression: "clipDataShow",
          },
        },
        [_c("clip-data", { attrs: { clip_data: _vm.clip_data } })],
        1
      ),
      _vm.knowledge
        ? _c(
            "div",
            { staticClass: "law-citation" },
            [
              _c(
                "fb-header",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c(
                    "fb-button",
                    {
                      attrs: {
                        slot: "left",
                        icon: "back",
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.knowledge = false
                        },
                      },
                      slot: "left",
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: { swipeable: "" },
                      on: { change: _vm.handleChangeTab },
                      model: {
                        value: _vm.active,
                        callback: function ($$v) {
                          _vm.active = $$v
                        },
                        expression: "active",
                      },
                    },
                    [
                      _c(
                        "van-tab",
                        {
                          attrs: {
                            title: "引用判例" + _vm.panliname + "篇",
                            name: 0,
                          },
                        },
                        [
                          _vm.loading
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    margin: "20px auto 20px",
                                  },
                                },
                                [
                                  _c("van-loading", {
                                    attrs: { color: "#1989fa" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.active == "0"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "space-between",
                                    padding: "10px 5px",
                                    "box-sizing": "border-box",
                                    "background-color":
                                      "rgba(242, 242, 242, 1)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "law-detail-top",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _vm._l(_vm.caseTree, function (item, i) {
                                        return _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.panliChecked,
                                                expression: "!panliChecked",
                                              },
                                            ],
                                            key: i,
                                            staticStyle: {
                                              flex: "1",
                                              "text-align": "center",
                                            },
                                            on: { click: _vm.ShowFilter },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                            _c("van-icon", {
                                              attrs: { name: "arrow-down" },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.panliChecked,
                                              expression: "panliChecked",
                                            },
                                          ],
                                          staticStyle: {
                                            color: "#409eff",
                                            display: "flex",
                                          },
                                        },
                                        [
                                          _c("van-checkbox", {
                                            staticStyle: {
                                              "margin-left": "14px",
                                            },
                                            attrs: { shape: "square" },
                                            on: { change: _vm.changeCheckAll },
                                            model: {
                                              value: _vm.panliCheckedAll,
                                              callback: function ($$v) {
                                                _vm.panliCheckedAll = $$v
                                              },
                                              expression: "panliCheckedAll",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              on: { click: _vm.cancel },
                                            },
                                            [
                                              _vm._v(
                                                "取消(" +
                                                  _vm._s(_vm.panliCheckedNum) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              on: { click: _vm.share },
                                            },
                                            [_vm._v("分享")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              on: { click: _vm.copy },
                                            },
                                            [_vm._v("复制")]
                                          ),
                                          _vm.userInfo
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.joinCollectBox()
                                                    },
                                                  },
                                                },
                                                [_vm._v("加入收集箱")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "panli" },
                            [
                              _vm._l(_vm.caselaelist, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "22px" } },
                                      [
                                        _c("van-checkbox", {
                                          attrs: { shape: "square" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheck(index)
                                            },
                                          },
                                          model: {
                                            value: item.checked,
                                            callback: function ($$v) {
                                              _vm.$set(item, "checked", $$v)
                                            },
                                            expression: "item.checked",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "contet",
                                        staticStyle: { flex: "1" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#000",
                                              "font-weight": "bold",
                                              display: "flex",
                                            },
                                          },
                                          [
                                            item.case_attr !== "普通案例" &&
                                            item.case_attr
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "white",
                                                      "text-align": "center",
                                                      "line-height": "35px",
                                                      "min-width": "90px",
                                                      background: "#ff9c39",
                                                      height: "35px",
                                                      "z-index": "999",
                                                      "border-radius": "3px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.case_attr) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "min-height": "35px",
                                                  "margin-left": "5px",
                                                  color: "#333",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.case_name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          item.court_name
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(item.court_name) + "/"
                                                ),
                                              ])
                                            : _vm._e(),
                                          item.case_id
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(item.case_id) + "/"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(_vm._s(item.pub_date)),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm.panliname >= 10
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333",
                                        margin: "10px auto 10px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.next(0)
                                        },
                                      },
                                    },
                                    [_vm._v("下一页")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "van-tab",
                        {
                          attrs: {
                            title: "引用知识" + _vm.zhishiname + "篇",
                            name: 1,
                          },
                        },
                        [
                          _vm.loading
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    margin: "20px auto 20px",
                                  },
                                },
                                [
                                  _c("van-loading", {
                                    attrs: { color: "#1989fa" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "panli" },
                            [
                              _vm._l(_vm.caselaelist, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "contet" },
                                  [
                                    _c("div", [
                                      _vm._v(" " + _vm._s(item.title) + " "),
                                    ]),
                                    _c("div", [
                                      item.origin
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.origin) + "/"),
                                          ])
                                        : _vm._e(),
                                      item.pub_date
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.pub_date) + "/"),
                                          ])
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(_vm._s(item.publisher)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              _vm.zhishiname >= 10
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333",
                                        margin: "10px auto 10px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.next(2)
                                        },
                                      },
                                    },
                                    [_vm._v("下一页")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "keep-alive",
        [
          Object.keys(_vm.TreeSelect.data).length > 0
            ? _c("treeSelect", {
                attrs: { keywords: [] },
                on: { search: _vm.pushFilterCondition },
                model: {
                  value: _vm.TreeSelect,
                  callback: function ($$v) {
                    _vm.TreeSelect = $$v
                  },
                  expression: "TreeSelect",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }