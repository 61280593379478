var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-menu" },
    _vm._l(_vm.catalog, function (item, index) {
      return _c(
        "ul",
        { key: "catalog-" + index },
        [_c("Tree", { attrs: { data: item } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }