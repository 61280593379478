/**
 * 本文件主要是提供一些数据转换方法
 */
import { typeOf, deepCopy, isFilledArray, parseJSON } from './assist'

// 拍平数据 以[条]为最小单位
export const makeTreeNodeToFlat = arr => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return []
  }
  const res = []
  const fn = source => {
    source.forEach(el => {
      if (typeOf(el) === 'object') {
        const { name, type, level, values: v } = el
        res.push({
          ...el,
          id: `${level || '0'}-${type}-${name}`,
          $name: '',
          checked: false,
        })
        // 递归子级
        if (isFilledArray(v)) {
          fn(v)
        }
      }
    })
  }
  fn(arr)
  return res
}

/**
 * 为数组对象添加key值
 * @param {Array} arr 数组
 * @param {String} keyName 键名
 * @param {Any} val 键值
 * @param {Boolean} recursive 是否递归
 * @param {Array} childrenName 子级键名称
 */
export const addKeyForArray = (arr, keyName, val, recursive, childrenName) => {
  if (!recursive) {
    return arr.map(item => {
      if (typeOf(item) === 'object') {
        const temp = { ...item }
        temp[keyName] = val
        return temp
      }
      return item
    })
  }
  return arr.map(item => {
    if (typeOf(item) === 'object') {
      const temp = { ...item }
      temp[keyName] = val
      return temp
    }
    if (item[childrenName] && isFilledArray(item[childrenName])) {
      return addKeyForArray(item[childrenName])
    }
    return item
  })
}

// 树形数据添加层级字段
export const arrayTreeSetLevel = (
  array,
  levelName = 'level',
  childrenName = 'children'
) => {
  if (!Array.isArray(array)) return []
  const recursive = (array, level = 0) => {
    level++
    return array.map(v => {
      if (typeof v === 'object') {
        v[levelName] = level
        const child = v[childrenName]
        if (child && child.length) recursive(child, level)
      }
      return v
    })
  }
  return recursive(array)
}

// 统计树型数据叶子节点的总数
export function setLeafCount(
  tree,
  childName = 'children',
  countName = 'leafCount'
) {
  if (
    typeOf(tree) === 'object' &&
    Reflect.has(tree, childName) &&
    Array.isArray(tree[childName]) &&
    tree[childName].length > 0
  ) {
    tree[countName] = tree[childName].reduce((pre, cur) => {
      return pre + setLeafCount(cur, childName, countName)
    }, 0)

    return tree[countName]
  } else {
    // 叶子节点
    return 1
  }
}

// 设置节点的起始值
export function setLeafStart(
  arr,
  start,
  childKey = 'children',
  countName = 'leafCount'
) {
  arr.reduce((pre, cur) => {
    if (cur[countName] && !cur.from) {
      cur.from = pre
      cur.range = { from: pre, to: pre + cur[countName] - 1 }
    }
    if (cur[childKey] && cur[childKey].length > 0) {
      setLeafStart(cur[childKey], pre, childKey, countName)
    }
    return pre + cur[countName]
  }, start)
}

// 将法律接口返回的目录数据转为Element-Tree组件需要的树形数据 并添加上范围属性
export const makeCatalogToTree = nodeArr => {
  // 将最后一层数据 字符串转为对象格式
  const makeStringToObj = arr => {
    arr.map(item => {
      const { value } = item
      if (isFilledArray(value)) {
        const isString = value.every(p => typeOf(p) === 'string')
        if (isString) {
          item.value = value.map(p => {
            return { name: p, type: '条', next: '0' }
          })
        } else {
          makeStringToObj(value)
        }
      }
    })
    return arr
  }
  const piped = makeStringToObj(deepCopy(nodeArr))
  /**
   * step1.统计每个节点的叶子节点总数
   * step2.根据给定的起始值和叶子节点总数循环设置 范围值(range: { form, to })
   */
  const childKey = 'value'
  piped.forEach(tree => {
    setLeafCount(tree, childKey)
  })
  setLeafStart(piped, 1, childKey)
  return piped
}

// 将字符串文本内容转为对象 添加 checked 字段以用来支持勾选
export const addCheckStateForText = arr => {
  return arr.map(block => {
    const v = block.values
    const isText = Array.isArray(v) && v.every(p => typeOf(p) === 'string')
    const texts = isText
      ? v.map(p => ({ checked: false, text: p, $text: '' }))
      : []
    return { ...block, texts }
  })
}

// 大数据检索 添加selected字段
export const addSelectedKeyField = (sourceArr, keyField, targetArr) => {
  const list = deepCopy(sourceArr)
  return list.map(item => {
    const find = targetArr.find(t => t[keyField] === item[keyField])
    return { ...item, selected: !!find }
  })
}

export function splitArrayByNum(e, l) {
  if (Array.isArray(e) && e.length === 0) {
    return e
  }
  var num = 0
  var _data = []
  for (let i = 0; i < e.length; i++) {
    if (i % l === 0 && i !== 0) {
      _data.push(e.slice(num, i))
      num = i
    }
    if (i + 1 === e.length) {
      _data.push(e.slice(num, i + 1))
    }
  }
  return _data
}

// 将大数据检索参数转为 合同 类型接口的参数
export const convertToAgreementParam = params => {
  const data = {}
  if (params) {
    const {
      query_condition: qc,
      filter_condition: fc,
      loc_condition: loc,
      sort,
      offset,
    } = params
    let keyWord = ''
    if (qc && qc.length) {
      qc.forEach((v, i) => {
        keyWord += v.key + '##'
      })
    }
    if (loc && loc.length) {
      loc.forEach(item => {
        item.value.forEach((v, i) => {
          keyWord += v.key + '##'
        })
      })
    }
    const lastIndex = keyWord.lastIndexOf('##')
    data.keyWord = keyWord.substr(0, lastIndex)
    if (fc && fc.length) {
      fc.forEach(item => {
        if (item.key && item.id) {
          switch (item.key) {
            case 'objectType':
              if (Number(item.id) || Number(item.id) === 0) {
                data.objectType = Number(item.id)
              }
              break
            case 'resourceLabelList':
              if (!params.labelIdList) {
                data.labelIdList = []
              }
              data.labelIdList.push(item.id)
              break
            case 'htResourceTypeList':
              data.htResourceTypeId = item.id
              break
            case 'documentTypeList':
              data.documentTypeId = item.id
              break
            case 'industryList':
              data.industryId = item.id
              break
            case 'price':
              data.minPrice = item.id.split('-')[0]
              if (item.id.split('-')[1]) {
                data.maxPrice = item.id.split('-')[1]
              }
              break
            case 'region':
              data.region = item.value[0]
              break
          }
        }
      })
    }
    data.sortType = sort || 0
    data.pageNum = offset || 1
  }
  return data
}

// 解析网易云自定义通知数据
export const parseNotificationFromNim = arr => {
  if (!isFilledArray(arr)) return []
  return arr.map(item => {
    return { ...item, message: parseJSON(item.message) }
  })
}
