<template>
  <div class="law-block" :data-id="id" :id="id" v-if="hasContent">
    <template v-if="titleShouldRender">
      <law-title :isCleanMode="isCleanMode" :source="source" :hasTexts="hasTexts" />
    </template>
    <template v-if="hasAttr">
      <law-attr :attr="source.attr" />
    </template>
    <template v-if="hasTexts">
      <law-text v-for="(text, i) in texts" :key="`${id}-${i}`" :index="i" :item="text" :isCleanMode="isCleanMode" />
    </template>
    <template v-if="hasRele">
      <law-rele :source="source" />
    </template>
    <template v-if="hasAdditions">
      <span class="addition-popup-trigger" @click="emitOpenAdditionPopup" :style="additionPopupTriggerStyle"
        ><svg t="1646731032296" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2171" width="20" height="20">
          <path d="M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123.392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z" p-id="2172" fill="#ff9a01"></path>
        </svg>
      </span>
    </template>
  </div>
</template>

<script>
import Emitter from '@/mixins/emitter'
import Mixin from './mixins'

import LawTitle from './law-title'
import LawAttr from './law-attr'
import LawText from './law-text'
import LawRele from './law-rele'

import './style.styl'

export default {
  name: 'law-block',
  props: {
    // 下标
    index: {
      type: Number,
    },
    // 简洁模式
    isCleanMode: {
      type: Boolean,
    },
    // 块级数据源
    source: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  components: {
    LawTitle,
    LawAttr,
    LawText,
    LawRele,
  },
  mixins: [Emitter, Mixin],
  data() {
    return {
      additionPopupVisible: false,
    }
  },
  provide() {
    return {
      id: this.id,
    }
  },
  computed: {
    id({ source }) {
      return source.id
    },
    // 标题是否要渲染
    titleShouldRender({ source }) {
      const { name } = source
      return name && name !== '正文'
    },
    // 有内容可以渲染
    hasContent() {
      return this.titleShouldRender || this.hasTexts || this.hasAdditions
    },
    // 含有需要渲染的文字 values数据有值且类型均为string
    hasTexts({ source }) {
      const v = source.texts
      return v && Array.isArray(v) && v.length > 0
    },
    // 有额外属性需要
    hasAttr({ source }) {
      const { attr } = source
      return attr && Array.isArray(attr)
    },
    // 有关联法条
    hasRele({ source }) {
      if (!source.law_relevancy && !Array.isArray(source.law_relevancy)) {
        return false
      } else {
        return true
      }
    },
    // 法条列表
    additions({ source }) {
      if (!source.addition_data || !Array.isArray(source.addition_data)) {
        return []
      }
      return source.addition_data.filter((addi) => {
        const { values: v } = addi
        return Array.isArray(v) && v.length > 0
      })
    },
    // 有关联法条
    hasAdditions() {
      return this.additions.length > 0
    },
    // 文本
    texts() {
      return this.source.texts || []
    },
    // 关联法条触发器样式
    additionPopupTriggerStyle() {
      if (this.isCleanMode) {
        return { visibility: 'hidden' }
      }
      return { visibility: 'visible' }
    },
  },
  methods: {
    // 灰色区域的显示隐藏
    show(flag) {
      const payload = {
        flag: flag,
        id: this.id,
      }
      this.dispatch('law-scroller', 'on-law-addition-show', payload)
    },
    emitOpenAdditionPopup() {
      this.dispatch('law-scroller', 'on-open-addition', {
        id: this.id,
        additions: this.additions,
      })
    },
  },
  mounted() {},
  created() {},
}
</script>

<style lang="stylus" scoped>
.law-block
  padding 10px
  position relative
  & .law-addition-box
    position relative
  & .law-addition-show-icon
    font-size 20px
    position absolute
    top: -30px;
    right: -30px
    color: #f90;
    cursor: pointer;
  &:last-child
    padding-bottom 20px
  .text ,.title
    position relative
    font-size 16px
    &.large
      text-align center
      font-weight 600
      font-size 20px
      line-height 20px
      &.l-0
        font-size 28px
        line-height 28px
        margin-top 20px
      &.l-1
        font-size 20px
        line-height 20px
      &.l-2
        font-size 24px
        line-height 24px
      &.l-3
        font-size 28px
        line-height 28px
    &.small
      font-weight 500
      font-size 18px
      >>> .after-tip
        & i.iconfont
          font-size 18px
  & .title
    margin-bottom 5px
  & .addition-popup-trigger
    display inline-block
    position relative
    top 6px
    color #ff9a01
::v-deep .law-text{
  display inline
  }
</style>
