<template>
  <div class="title" :class="titleClassName">
    <i
      v-if="isTypeTiao"
      :style="cleanModeVisibleStyle"
      class="fake-check-box"
      :class="checkBoxClassName"
      @click.stop="onCheck"
    ></i>
    <span class="text-container">
      <span style="font-weight: bold" v-html="titleText"></span>

      <after-tip :source="afterTipData" :options="afterTipOptions" />
    </span>
    <i
      v-show="!isCleanMode"
      class="iconfont icon-link1"
      v-if="isShowClipData"
      @click="showClipData(source.clip_data)"
    ></i>
    <div
      v-show="!isCleanMode"
      class="right-num dropdown"
      v-if="
        source.reflaw_num !== undefined && source.cite_ledge_num !== undefined
      "
    >
      <span>{{ source.reflaw_num }}</span>
      <span>
        /
      </span>
      <span>{{ source.cite_ledge_num }}</span>
      <div class="dropdown-content">
        <p @click="showQuote(0)">被{{ source.quote_num || 0 }}篇判例引用</p>
        <p @click="showQuote(1)">
          被{{ source.cite_ledge_num || 0 }}篇知识引用
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from '@/mixins/emitter'
import Mixin from './mixins'

import AfterTip from '../after-tip'

export default {
  name: 'law-title',
  props: {
    source: {
      type: Object,
      default: _ => {}
    },
    // 是否有下级文本
    hasTexts: {
      type: Boolean,
      default: _ => false
    },
    // 简洁模式
    isCleanMode: {
      type: Boolean
    }
  },
  mixins: [Emitter, Mixin],
  inject: ['id'],
  components: { AfterTip },
  data() {
    return {}
  },
  computed: {
    checked() {
      return this.source.checked
    },
    // 标题文本
    titleText() {
      let str = this.source.$name || this.source.name
      const startIndex = str.indexOf('【')
      const endIndex = str.indexOf('】')
      if (startIndex > -1 && endIndex > -1) {
        str =
          str.slice(0, startIndex + 1) +
          "<span style='opacity: 0.5;'>" +
          str.slice(startIndex + 1, endIndex) +
          '</span>' +
          str.slice(endIndex)
      }
      return str
    },
    // 是否为【条】类型
    // 目前共四种类型： 章 节 篇 | 条
    isTypeTiao() {
      return this.source.type === '条'
    },
    // 标题样式名
    titleClassName() {
      const { level } = this.source
      return this.isTypeTiao ? 'small' : `large l-${level}`
    },
    // 文本后方tip数据
    afterTipData() {
      return { text: this.titleText, id: this.id }
    },
    /** 文本后方的操作图标
     * 如果有下级文本则多显示 复制块级 图标
     * */

    afterTipOptions() {
      if (this.hasTexts) {
        return ['COPY', 'COPYBLOCK']
      }
      return ['COPY']
    },
    // 是否显示曲别针标签
    isShowClipData() {
      if (this.source.clip_data) {
        let flag = false
        this.source.clip_data.forEach(item => {
          if (item.values.length > 0) {
            flag = true
          }
        })
        return flag
      } else {
        return false
      }
    }
  },
  methods: {
    // 选框点击
    onCheck() {
      console.log('选框点击')
      const payload = {
        id: this.source.id,
        type: 'TITLE',
        text: this.titleText,
        checked: this.checked
      }
      this.dispatch('law-scroller', 'on-law-check', payload)
    },

    // 曲别针数据
    showClipData(clipData) {
      this.dispatch(
        'law-scroller',
        'on-show-law-clip-data',
        JSON.stringify(clipData)
      )
    },

    // 引用判例等数据
    showQuote(type) {
      this.dispatch('law-scroller', 'on-show-law-quote', {
        type: type,
        name: this.source.name,
        reflaw_num: this.source.reflaw_num,
        cite_ledge_num: this.source.cite_ledge_num
      })
    }
  },
  mounted() {},
  created() {}
}
</script>
<style lang="stylus" scoped>
.title
  & .after-tip
    position relative
    float right
    right 5px
    top 0
    background rgba(255,255,255, 0.6)
    z-index 3
  .icon-link1
    cursor pointer
    font-weight: bold
  .right-num
    float right
    cursor pointer
    color darkgray
    position: relative;
.dropdown-content
  white-space nowrap
  font-size 13px
  display none
  position absolute
  background-color rgb(243, 246, 245)
  min-width 120px
  box-shadow 0px 8px 16px 0px rgba(0, 0, 0, 0.2)
  padding 12px 16px
  z-index 1
  // left: -50px
  right 0px
  color rgb(91, 220, 217)
.dropdown:hover .dropdown-content
  display block
</style>
