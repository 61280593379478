var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-tabs",
        {
          attrs: { ellipsis: false, border: "" },
          on: { click: _vm.onClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabsList, function (item, index) {
          return _c(
            "van-tab",
            {
              key: index,
              attrs: {
                title: `${Object.keys(item)[0]}(${
                  Object.values(item)[0] > 1000
                    ? "999+"
                    : Object.values(item)[0]
                })`,
                name: Object.keys(item)[0],
              },
            },
            [
              _c(
                "div",
                [
                  _vm.activeName === "关联法条" ||
                  _vm.activeName === "司法解释" ||
                  _vm.activeName === "探索法"
                    ? [
                        _vm._l(_vm.dataList, function (arr, num) {
                          return _c(
                            "div",
                            {
                              key: arr.law_title + num,
                              staticClass: "show",
                              staticStyle: { padding: "10px 18px" },
                            },
                            [
                              arr.timeliness && arr.timeliness !== "现行有效"
                                ? _c(
                                    "van-collapse",
                                    {
                                      attrs: { accordion: "" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeCollape(num)
                                        },
                                      },
                                      model: {
                                        value: _vm.collapseNum,
                                        callback: function ($$v) {
                                          _vm.collapseNum = $$v
                                        },
                                        expression: "collapseNum",
                                      },
                                    },
                                    [
                                      _c(
                                        "van-collapse-item",
                                        {
                                          attrs: { name: num },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "hover_p",
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#eb7c8a",
                                                                "margin-right":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  arr.timeliness
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                arr.law_title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "right-icon",
                                                fn: function () {
                                                  return [
                                                    arr.fatiao &&
                                                    arr.fatiao.length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "jiantouBox",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "共 " +
                                                                  _vm._s(
                                                                    arr.fatiao
                                                                      .length
                                                                  ) +
                                                                  " 条"
                                                              ),
                                                            ]),
                                                            _vm.collapseNum ===
                                                            num
                                                              ? _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "jiantou",
                                                                    attrs: {
                                                                      t: "1640927746587",
                                                                      viewBox:
                                                                        "0 0 1024 1024",
                                                                      version:
                                                                        "1.1",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                      "p-id":
                                                                        "2115",
                                                                      width:
                                                                        "20",
                                                                      height:
                                                                        "20",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        d: "M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z",
                                                                        "p-id":
                                                                          "2116",
                                                                        fill: "#ff9900",
                                                                      },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "jiantou",
                                                                    staticStyle:
                                                                      {
                                                                        transform:
                                                                          "rotate(-90deg)",
                                                                      },
                                                                    attrs: {
                                                                      t: "1640927746587",
                                                                      viewBox:
                                                                        "0 0 1024 1024",
                                                                      version:
                                                                        "1.1",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                      "p-id":
                                                                        "2115",
                                                                      width:
                                                                        "20",
                                                                      height:
                                                                        "20",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        d: "M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z",
                                                                        "p-id":
                                                                          "2116",
                                                                        fill: "#ff9900",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          arr.fatiao
                                            ? [
                                                _c("div", [
                                                  _vm.activeName ===
                                                    "司法解释" ||
                                                  _vm.activeName === "探索法"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "case_info",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                arr.pub_date
                                                              ) +
                                                              "发布 /" +
                                                              _vm._s(
                                                                arr.exc_date
                                                              ) +
                                                              "实施 / " +
                                                              _vm._s(
                                                                arr.potency_child
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "case_info",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                arr.publish_machinery
                                                              ) +
                                                              " / " +
                                                              _vm._s(
                                                                arr.pub_date
                                                              ) +
                                                              "发布 / " +
                                                              _vm._s(
                                                                arr.exc_date
                                                              ) +
                                                              "实施 / " +
                                                              _vm._s(
                                                                arr.potency_child
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ]),
                                                _vm._l(
                                                  arr.fatiao,
                                                  function (child, number) {
                                                    return _c(
                                                      "div",
                                                      { key: "-c-" + number },
                                                      [
                                                        _c(
                                                          "law-addition-text",
                                                          {
                                                            attrs: {
                                                              childItem2: child,
                                                              childItem: arr,
                                                              item: Object.keys(
                                                                item
                                                              )[0],
                                                            },
                                                            on: {
                                                              copy: _vm.copy,
                                                              toDetail:
                                                                _vm.toDetail,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "title_p hover_p",
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(arr.law_title) + " "
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _vm.activeName === "司法解释"
                                          ? _c(
                                              "p",
                                              { staticClass: "case_info" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(arr.pub_date) +
                                                    "发布 / " +
                                                    _vm._s(arr.exc_date) +
                                                    "实施 / " +
                                                    _vm._s(arr.potency_child) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              { staticClass: "case_info" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      arr.publish_machinery
                                                    ) +
                                                    " / " +
                                                    _vm._s(arr.pub_date) +
                                                    "发布 / " +
                                                    _vm._s(arr.exc_date) +
                                                    "实施 / " +
                                                    _vm._s(arr.potency_child) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ]),
                                      arr.fatiao && arr.fatiao.length
                                        ? _vm._l(
                                            arr.fatiao,
                                            function (child, number) {
                                              return _c(
                                                "div",
                                                { key: "-c-" + number },
                                                [
                                                  _c("law-addition-text", {
                                                    attrs: {
                                                      childItem2: child,
                                                      childItem: arr,
                                                      checkedAll: arr.checked,
                                                      item: Object.keys(
                                                        item
                                                      )[0],
                                                    },
                                                    on: {
                                                      copy: _vm.copy,
                                                      toDetail: _vm.toDetail,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          )
                        }),
                        _vm.isShowMore && _vm.delayMore
                          ? _c("div", { staticClass: "loadMore" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getMore("law")
                                    },
                                  },
                                },
                                [_vm._v("加载更多")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm.activeName === "行政法规" ||
                      _vm.activeName === "地方法规"
                    ? [
                        _vm._l(_vm.dataList, function (arr, num) {
                          return _c(
                            "div",
                            {
                              key: arr.law_title + num,
                              staticClass: "show",
                              staticStyle: { padding: "10px 18px" },
                            },
                            [
                              arr.timeliness && arr.timeliness !== "现行有效"
                                ? _c(
                                    "van-collapse",
                                    {
                                      attrs: { accordion: "" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeCollape(num)
                                        },
                                      },
                                      model: {
                                        value: _vm.collapseNum,
                                        callback: function ($$v) {
                                          _vm.collapseNum = $$v
                                        },
                                        expression: "collapseNum",
                                      },
                                    },
                                    [
                                      _c(
                                        "van-collapse-item",
                                        {
                                          attrs: { name: num },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "hover_p",
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#eb7c8a",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                arr.timeliness
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              arr.law_title
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copy-div",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "iconfont icon-fuzhi1 copy-icon",
                                                              attrs: {
                                                                title:
                                                                  "复制本段内容",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.copy(
                                                                      arr.law_title
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("i", {
                                                              staticClass:
                                                                "iconfont icon-send copy-icon",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.toDetail(
                                                                      arr
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "right-icon",
                                                fn: function () {
                                                  return [
                                                    arr.fatiao &&
                                                    arr.fatiao.length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "jiantouBox",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "共 " +
                                                                  _vm._s(
                                                                    arr.fatiao
                                                                      .length
                                                                  ) +
                                                                  " 条"
                                                              ),
                                                            ]),
                                                            _vm.collapseNum ===
                                                            num
                                                              ? _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "jiantou",
                                                                    attrs: {
                                                                      t: "1640927746587",
                                                                      viewBox:
                                                                        "0 0 1024 1024",
                                                                      version:
                                                                        "1.1",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                      "p-id":
                                                                        "2115",
                                                                      width:
                                                                        "20",
                                                                      height:
                                                                        "20",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        d: "M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z",
                                                                        "p-id":
                                                                          "2116",
                                                                        fill: "#ff9900",
                                                                      },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "jiantou",
                                                                    staticStyle:
                                                                      {
                                                                        transform:
                                                                          "rotate(-90deg)",
                                                                      },
                                                                    attrs: {
                                                                      t: "1640927746587",
                                                                      viewBox:
                                                                        "0 0 1024 1024",
                                                                      version:
                                                                        "1.1",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                      "p-id":
                                                                        "2115",
                                                                      width:
                                                                        "20",
                                                                      height:
                                                                        "20",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        d: "M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z",
                                                                        "p-id":
                                                                          "2116",
                                                                        fill: "#ff9900",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          arr.fatiao && arr.fatiao.length
                                            ? [
                                                _c("div", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "case_info",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          arr.publish_machinery
                                                        ) +
                                                          " / " +
                                                          _vm._s(arr.pub_date) +
                                                          "发布 / " +
                                                          _vm._s(arr.exc_date) +
                                                          "实施 / " +
                                                          _vm._s(
                                                            arr.potency_child
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._l(
                                                  arr.fatiao,
                                                  function (val, idx) {
                                                    return _c(
                                                      "div",
                                                      { key: idx },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "hover_p",
                                                            staticStyle: {
                                                              "font-family":
                                                                "Microsoft Yahei",
                                                              "font-size":
                                                                "24px",
                                                              "font-weight":
                                                                "bold",
                                                              color: "#333",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  val.title
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "copy-div",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-fuzhi1 copy-icon",
                                                                  attrs: {
                                                                    title:
                                                                      "复制本段内容",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copy(
                                                                          arr.law_title
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-send copy-icon",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toDetail(
                                                                          arr
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "hover_p",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  val.text
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "copy-div",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-fuzhi1 copy-icon",
                                                                  attrs: {
                                                                    title:
                                                                      "复制本段内容",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copy(
                                                                          arr.law_title
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-send copy-icon",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toDetail(
                                                                          arr
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "title_p hover_p",
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(arr.law_title) + " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "copy-div" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-fuzhi1 copy-icon",
                                                attrs: {
                                                  title: "复制本段内容",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copy(
                                                      arr.law_title
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-send copy-icon",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toDetail(arr)
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _c("p", { staticClass: "case_info" }, [
                                          _vm._v(
                                            _vm._s(arr.publish_machinery) +
                                              " / " +
                                              _vm._s(arr.pub_date) +
                                              "发布 / " +
                                              _vm._s(arr.exc_date) +
                                              "实施 / " +
                                              _vm._s(arr.potency_child)
                                          ),
                                        ]),
                                      ]),
                                      arr.fatiao && arr.fatiao.length
                                        ? _vm._l(
                                            arr.fatiao,
                                            function (val, idx) {
                                              return _c("div", { key: idx }, [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "hover_p",
                                                    staticStyle: {
                                                      "font-family":
                                                        "Microsoft Yahei",
                                                      "font-size": "14px",
                                                      "font-weight": "bold",
                                                      color: "#333",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(val.title) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "copy-div",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "iconfont icon-fuzhi1 copy-icon",
                                                          attrs: {
                                                            title:
                                                              "复制本段内容",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copy(
                                                                arr.law_title
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("i", {
                                                          staticClass:
                                                            "iconfont icon-send copy-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toDetail(
                                                                arr
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "hover_p" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(val.text) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "copy-div",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "iconfont icon-fuzhi1 copy-icon",
                                                          attrs: {
                                                            title:
                                                              "复制本段内容",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copy(
                                                                arr.law_title
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("i", {
                                                          staticClass:
                                                            "iconfont icon-send copy-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toDetail(
                                                                arr
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          )
                        }),
                        _vm.isShowMore && _vm.delayMore
                          ? _c("div", { staticClass: "loadMore" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getMore("law")
                                    },
                                  },
                                },
                                [_vm._v("加载更多")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm.activeName === "立法解读" && _vm.lifajieduList.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "show",
                            staticStyle: { padding: "20px 40px" },
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "lifajiedu_title" }, [
                                _vm._v("条文主旨"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-indent": "40px",
                                    padding: "10px 0",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.lifajieduList[3]) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _c("div", { staticClass: "lifajiedu_title" }, [
                                _vm._v("条文解读"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-indent": "40px",
                                    padding: "10px 0",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.lifajieduList.slice(5).join("")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm.activeName === "法条沿革" &&
                      _vm.fatiaoyangeList.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "show",
                            staticStyle: { padding: "10px 18px" },
                          },
                          [
                            _c("div", { staticClass: "top_left_img_box" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/top-left.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "img_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.fatiaoyangeList[1].type) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "van-steps",
                                  {
                                    attrs: {
                                      direction: "vertical",
                                      "active-color": "#f7b552",
                                      active: "0",
                                      "active-icon": "circle",
                                    },
                                  },
                                  _vm._l(
                                    _vm.fatiaoyangeList,
                                    function (ite, idx) {
                                      return _c("van-step", { key: idx }, [
                                        _c("h3", [
                                          _vm._v(_vm._s(ite.law_title)),
                                        ]),
                                        _c("p", [_vm._v(_vm._s(ite.text))]),
                                        _c("p", { staticClass: "step_time" }, [
                                          _vm._v("2016-07-11"),
                                        ]),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm.activeName === "新旧对照" &&
                      _vm.xinjiuduizhaoList.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "show",
                            staticStyle: { padding: "10px 18px" },
                          },
                          [
                            _c("div", { staticClass: "top_left_img_box" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/top-left.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "img_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.xinjiuduizhaoList[0]?.type) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "newAndOld" }, [
                              _c("div", { staticClass: "tip_box" }, [
                                _c("div", { staticClass: "tip_text_bl" }, [
                                  _vm._v("保留文字"),
                                ]),
                                _c("div", { staticClass: "tip_text_sc" }, [
                                  _vm._v("删除文字"),
                                ]),
                                _c("div", { staticClass: "tip_text_xz" }, [
                                  _vm._v("新增文字"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "new_and_old_content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "new_and_old_content_time" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.xinjiuduizhaoList[0]
                                              .publish_date
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticStyle: { flex: "1" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "new_and_old_content_title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.xinjiuduizhaoList[0].law_title
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.xinjiuduizhaoList[0].text)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : [
                        _vm._l(_vm.dataList, function (arr, num) {
                          return _c(
                            "div",
                            {
                              key: num,
                              staticClass: "show",
                              staticStyle: { padding: "10px 18px 0" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "title_p hover_p",
                                  staticStyle: { "margin-right": "10px" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        arr.case_name || arr.Title || arr.title
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "copy-div" }, [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-fuzhi1 copy-icon",
                                      attrs: { title: "复制本段内容" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            arr.case_name ||
                                              arr.Title ||
                                              arr.title
                                          )
                                        },
                                      },
                                    }),
                                    _vm.activeName !== "处罚文书"
                                      ? _c("i", {
                                          staticClass:
                                            "iconfont icon-send copy-icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toDetail(arr)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c("div", [
                                _vm.activeName === "知识引用"
                                  ? _c("p", { staticClass: "case_info" }, [
                                      _vm._v(
                                        _vm._s(arr.publisher) +
                                          " / " +
                                          _vm._s(arr.pub_date)
                                      ),
                                    ])
                                  : _c("p", { staticClass: "case_info" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            arr.court_name || arr.LawDepartment
                                          ) +
                                          " / " +
                                          _vm._s(
                                            arr.case_id || arr.DocumentNO
                                          ) +
                                          " / " +
                                          _vm._s(
                                            arr.judge_date ||
                                              arr.PunishmentDate ||
                                              arr.pub_date
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]),
                            ]
                          )
                        }),
                        _vm.isShowMore && _vm.delayMore
                          ? _c("div", { staticClass: "loadMore" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getMore("case")
                                    },
                                  },
                                },
                                [_vm._v("加载更多")]
                              ),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }