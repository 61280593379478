var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addition-content-text" }, [
    _vm.childItem2.title
      ? _c("div", { staticClass: "addition-content-title" }, [
          _c("span", { staticClass: "addition-can-hover" }, [
            _c(
              "div",
              {
                staticClass: "check-box",
                class: _vm.checked ? "checked" : "unchecked",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("van-checkbox", {
                  attrs: { "icon-size": "24px", shape: "square" },
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckChild($event, _vm.childItem)
                    },
                  },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                }),
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.childItem2.title) + " "),
            _c(
              "span",
              {
                staticClass: "addition-content-text-btn",
                staticStyle: { width: "100px" },
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-fuzhi1",
                  attrs: { title: "复制本段内容" },
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.childItem2.title)
                    },
                  },
                }),
                _c("i", {
                  staticClass: "iconfont icon-send",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(_vm.childItem, _vm.childItem2.title)
                    },
                  },
                }),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.childItem2.text
      ? _c("div", { staticClass: "addition-content-text-div" }, [
          _c("span", { staticClass: "addition-can-hover" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.setHtml(_vm.childItem2.text)) },
            }),
            _c("span", { staticClass: "addition-content-text-btn" }, [
              _c("i", {
                staticClass: "iconfont icon-fuzhi1",
                attrs: { title: "复制本段内容" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.copy(_vm.childItem2.text)
                  },
                },
              }),
              _c("i", {
                staticClass: "iconfont icon-send",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toDetail(_vm.childItem, _vm.childItem2.title)
                  },
                },
              }),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }