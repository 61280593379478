var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clip-data" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.clip_data, function (item, index) {
          return _c(
            "van-tab",
            {
              key: index,
              staticClass: "clip-data-box",
              attrs: { title: item.title, name: item.title },
            },
            [
              _c(
                "div",
                { staticClass: "clip-data-box-content" },
                _vm._l(item.values, function (childItem, i) {
                  return _c("div", { key: i }, [
                    typeof childItem === "string"
                      ? _c("div", {
                          staticClass: "clip-data-box-content-text",
                          domProps: { innerHTML: _vm._s(childItem) },
                        })
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "clip-data-box-content-title" },
                            [_vm._v(" " + _vm._s(childItem.law_title) + " ")]
                          ),
                          typeof childItem.text === "string"
                            ? _c("div", {
                                staticClass: "clip-data-box-content-text",
                                domProps: { innerHTML: _vm._s(childItem.text) },
                              })
                            : _vm._e(),
                        ]),
                  ])
                }),
                0
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }