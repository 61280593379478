var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addition_data && _vm.addition_data.length > 0
    ? _c("div", { staticClass: "law-addition" }, [
        _c(
          "div",
          { staticClass: "addition-list" },
          [
            _vm._l(_vm.addition_data, function (item, index) {
              return [
                item.values.length > 0
                  ? _c(
                      "div",
                      { key: item.title + index, staticClass: "addition-box" },
                      [
                        _c("div", { staticClass: "addition-title" }, [
                          _c("span", { staticClass: "addition-title-span" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.isHidden,
                                expression: "!item.isHidden",
                              },
                            ],
                            staticClass: "addition-content",
                          },
                          [
                            _vm._l(item.values, function (childItem, i) {
                              return _c(
                                "div",
                                {
                                  key: item.title + "-c2-" + i,
                                  staticClass: "addition-content-box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            i + 1 <= item.page * 10 ||
                                            item.page === undefined,
                                          expression:
                                            "i + 1 <= item.page * 10 || item.page === undefined",
                                        },
                                      ],
                                    },
                                    [
                                      item.title === "经典案例" ||
                                      item.title === "典型案例" ||
                                      item.title === "公报案例" ||
                                      item.title === "指导案例" ||
                                      item.title === "参阅案例"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "addition-content-title",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "addition-can-hover",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        childItem.case_name
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "addition-content-text-btn",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont icon-fuzhi1",
                                                        attrs: {
                                                          title: "复制本段内容",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.copy(
                                                              childItem.case_name
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont icon-send",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toDetail(
                                                              childItem
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "addition-content-title",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "addition-can-hover",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "check-box",
                                                      class: childItem.checked
                                                        ? "checked"
                                                        : "unchecked",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("van-checkbox", {
                                                        attrs: {
                                                          "icon-size": "24px",
                                                          shape: "square",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCheck(
                                                              $event,
                                                              childItem
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            childItem.checked,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              childItem,
                                                              "checked",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "childItem.checked",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  childItem.timeliness &&
                                                  childItem.timeliness !==
                                                    "现行有效"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              childItem.timeliness
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    _vm._s(
                                                      childItem.law_title
                                                    ) + " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "addition-content-text-btn",
                                                      staticStyle: {
                                                        width: "100px",
                                                        "font-weight": "normal",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont icon-fuzhi1",
                                                        attrs: {
                                                          title: "复制本段内容",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.copy(
                                                              childItem.law_title
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      item.title ===
                                                        "关联法条" ||
                                                      item.title === "司法解释"
                                                        ? _c("i", {
                                                            staticClass:
                                                              "iconfont icon-copy-block",
                                                            attrs: {
                                                              title:
                                                                "复制本条全部内容",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.copyBlock(
                                                                  childItem
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont icon-send",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toDetail(
                                                              childItem
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _vm._l(
                                    childItem.child,
                                    function (childItem2, i2) {
                                      return _c(
                                        "div",
                                        { key: item.title + "-c1-" + i2 },
                                        [
                                          _c("lawAdditionText", {
                                            attrs: {
                                              childItem2: childItem2,
                                              childItem: childItem,
                                              checkedAll: childItem.checked,
                                              item: item,
                                            },
                                            on: {
                                              copy: _vm.copy,
                                              copyBlockOne: _vm.copyBlockOne,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            item.page && item.values.length > item.page * 10
                              ? _c(
                                  "div",
                                  { staticClass: "addition-content-box" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "addition-content-title",
                                        staticStyle: { color: "#f90" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.next(index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 更多 ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }