<template>
  <div class="law-detail">
    <div class="nav-bar">
      <nav-bar :title="lawName" @menuEvent="handleNavbarEvent" />
      <!-- <nav-bar :title="lawName" @menuEvent="b" /> -->
    </div>
    <div class="content" :class="{ peek: isPeeking }">
      <van-loading v-show="isLoading" color="#1989fa" vertical>加载中...</van-loading>
      <div class="error" v-if="isError">
        <van-empty image="network" description="接口错误">
          <van-button round type="info" class="bottom-button" @click="retryFetchLaw">点击重试</van-button>
        </van-empty>
      </div>
      <law-scroller
        ref="lawScrollerRef"
        id="lawDetailWrapper"
        v-if="dataList.length && !isError"
        :isCleanMode="isCleanMode"
        :toCatalog="toCatalog"
        :catalog="catalog"
        :dataList="dataList"
        @onLawCheck="onLawCheck"
        @collect="handleCollect"
        @popLaw="onPopLaw"
        @clickClipDataEvent="clickClipDataEvent"
        @quoteClickEvent="quoteClickEvent"
        @onCopyBlock="onCopyBlock"
        @openInnerQuery="openInnerQueryPopup"
        @toggleMode="handleNavbarEvent('TOGGLE')"
      />
      <div class="kw-panel" v-show="isPeeking">
        <div class="kw-list">
          <van-tag v-for="kw in kwList" :key="kw" @click="toggleUse(kw)" :plain="!isNavKw(kw)" class="kw-tag" size="large" type="primary">{{ kw }}</van-tag>
        </div>
        <div class="kw-nav">
          <div class="act">
            <div class="quit" @click="exitPeeking">退出</div>
            <div class="edit" @click="openInnerQueryPopup">编辑</div>
          </div>
          <div class="posi">{{ hlCurrentNum }} / {{ hlTotalNum }}</div>
          <div class="nav">
            <van-icon name="arrow-up" @click="navKw('up')" />
            <van-icon name="arrow-down" @click="navKw('down')" />
          </div>
        </div>
      </div>
      <div v-if="showReport" class="collect-report">
        <searchReport @close="closeReport" from="law" />
      </div>
      <div v-if="wangpan" class="skyDrive">
        <skyDrive :listid="id" :Type="'lawid'" @CancelCollect="close" :listname="lawName" @getId="getId"></skyDrive>
      </div>
    </div>
    <van-popup v-model="queryPopupVisible" get-container="#outter" position="right" :style="{ height: '100%' }">
      <inner-query :class="{ peek: isPeeking }" :resultList="hlResult" ref="queryRef" @cancel="cancelInnerQueryPopup" @view="viewHighlight" @highlight="handleInnerQuery" />
    </van-popup>
    <van-popup v-model="lawPopupVisible" get-container="#outter" position="bottom" :style="{ height: '86%' }">
      <div class="pop-content">
        <van-loading v-show="lawPopupLoading" color="#1989fa" vertical>加载中...</van-loading>
        <law-scroller
          v-if="lawPopupDataList.length"
          :isCleanMode="true"
          :toCatalog="lawPopupToCatalog"
          :catalog="lawPopupCatalog"
          :dataList="lawPopupDataList"
          @popLaw="onPopLaw"
          @clickClipDataEvent="clickClipDataEvent"
          @onCopyBlock="onCopyBlock"
        />
      </div>
    </van-popup>

    <van-popup v-model="clipDataShow" position="left" :style="{ height: '100%', width: '70%' }">
      <clip-data :clip_data="clip_data" />
    </van-popup>

    <div v-if="knowledge" class="law-citation">
      <fb-header slot="header">
        <fb-button slot="left" icon="back" size="small" @click="knowledge = false" type="primary"> 返回 </fb-button>
      </fb-header>
      <div>
        <!-- 判例 -->
        <van-tabs v-model="active" swipeable @change="handleChangeTab">
          <van-tab :title="'引用判例' + panliname + '篇'" :name="0">
            <div style="width: 30px; height: 30px; margin: 20px auto 20px" v-if="loading">
              <van-loading color="#1989fa" />
            </div>
            <div v-if="active == '0'" style="display: flex; align-items: center; justify-content: space-between; padding: 10px 5px; box-sizing: border-box; background-color: rgba(242, 242, 242, 1)">
              <div class="law-detail-top" style="display: flex; align-items: center; flex: 1">
                <div v-for="(item, i) in caseTree" :key="i" style="flex: 1; text-align: center" @click="ShowFilter" v-show="!panliChecked">
                  <span>{{ item.title }}</span>
                  <van-icon name="arrow-down" />
                </div>
                <div v-show="panliChecked" style="color: #409eff; display: flex">
                  <van-checkbox style="margin-left: 14px" v-model="panliCheckedAll" shape="square" @change="changeCheckAll"></van-checkbox>
                  <span style="margin-left: 20px" @click="cancel">取消({{ panliCheckedNum }})</span>
                  <span style="margin-left: 20px" @click="share">分享</span>
                  <!-- <span
                    style="margin-left: 20px"
                    @click="downLoad"
                    v-if="userInfo"
                    >下载</span
                  > -->
                  <span style="margin-left: 20px" @click="copy">复制</span>
                  <span style="margin-left: 20px" v-if="userInfo" @click="joinCollectBox()">加入收集箱</span>
                </div>
              </div>
            </div>
            <div class="panli">
              <div style="display: flex" v-for="(item, index) in caselaelist" :key="index">
                <div style="margin-top: 22px">
                  <van-checkbox v-model="item.checked" shape="square" @change="changeCheck(index)"></van-checkbox>
                </div>
                <div class="contet" style="flex: 1">
                  <div style="font-size: 15px; color: #000; font-weight: bold; display: flex">
                    <div
                      style="color: white; text-align: center; line-height: 35px; min-width: 90px; background: #ff9c39; height: 35px; z-index: 999; border-radius: 3px"
                      v-if="item.case_attr !== '普通案例' && item.case_attr"
                    >
                      {{ item.case_attr }}
                    </div>
                    <div style="min-height: 35px; margin-left: 5px; color: #333; font-weight: bold">
                      {{ item.case_name }}
                    </div>
                  </div>
                  <div>
                    <span v-if="item.court_name">{{ item.court_name }}/</span>
                    <span v-if="item.case_id">{{ item.case_id }}/</span>
                    <span>{{ item.pub_date }}</span>
                  </div>
                </div>
              </div>
              <div @click="next(0)" style="color: #333; margin: 10px auto 10px; text-align: center" v-if="panliname >= 10">下一页</div>
            </div>
          </van-tab>
          <van-tab :title="'引用知识' + zhishiname + '篇'" :name="1">
            <div style="width: 30px; height: 30px; margin: 20px auto 20px" v-if="loading">
              <van-loading color="#1989fa" />
            </div>
            <div class="panli">
              <div class="contet" v-for="(item, index) in caselaelist" :key="index">
                <div>
                  {{ item.title }}
                </div>
                <div>
                  <span v-if="item.origin">{{ item.origin }}/</span>
                  <span v-if="item.pub_date">{{ item.pub_date }}/</span>
                  <span>{{ item.publisher }}</span>
                </div>
              </div>
              <div @click="next(2)" style="color: #333; margin: 10px auto 10px; text-align: center" v-if="zhishiname >= 10">下一页</div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <keep-alive>
      <treeSelect v-model="TreeSelect" v-if="Object.keys(TreeSelect.data).length > 0" :keywords="[]" @search="pushFilterCondition"></treeSelect>
    </keep-alive>
  </div>
</template>

<script>
import { makeTreeNodeToFlat, makeCatalogToTree, addCheckStateForText } from '@/utils/traverse'

import innerQueryMixin from './mixins/innerQuery'

import { deepCopy, copyTextToClipboard } from '@/utils/assist'

import NavBar from './components/nav-bar'
import InnerQuery from './components/inner-query'
import ClipData from './components/clip-data'

import LawScroller from '@/components/law-scroller'
import treeSelect from '@/pages/Search/compoments/treeSelect.vue'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'law-detail',
  props: {
    entry: {
      type: Object,
    },
    miniuserInfo: {
      type: Object,
    },
  },
  mixins: [innerQueryMixin],
  data() {
    return {
      // 加载中
      isLoading: false,
      // 接口错误
      isError: false,
      // 简洁模式
      isCleanMode: true,
      // 法条id
      id: '',
      // 法律接口原始数据
      law: {},
      // 目录
      catalog: [],
      // 法条列表
      dataList: [],
      toCatalog: '',
      // 弹出的法条
      lawPopupLoading: false,
      lawPopupVisible: false,
      lawPopupDataList: [],
      lawPopupToCatalog: '',
      clipDataShow: false,
      clip_data: [],
      // 弹框
      loading: true,
      knowledge: false,
      hatin: true,
      caseTree: [],
      panliCheckedAll: false,
      panliChecked: false,
      panliCheckedNum: 0,
      TreeSelect: {
        flag: false,
        data: {},
        title: '',
      },
      caselaelist: [],
      active: 0,
      contentObj: {}, // 当前页面中的收集箱正文中的对象
      contentArr: [], // 收集箱正文内容
      collectionArr: [],
      showReport: false,
      wangpan: false,
    }
  },
  components: {
    NavBar,
    InnerQuery,
    LawScroller,
    ClipData,
    treeSelect,
    searchReport: () => import('@/pages/transform/searchReport'),
    skyDrive: () => import('@/pages/skyDrive/index'),
  },
  computed: {
    ...mapState('searchReport', ['judgeReports', 'lawReports', 'criterionReports', 'knowledgeReports', 'contentReports', 'anaReports', 'textReport', 'clearCheck']),
    ...mapState('user', ['userInfo', 'userinfo']),
    ...mapState('nonmemberLimit', ['isValid']),
    // 背景资料
    lawInfo({ law }) {
      return law && law.back_info
    },
    // 法律名称
    lawName({ law }) {
      return (law && law.case_name) || ''
    },
    panliCheckList() {
      return this.caselaelist.filter((item) => item.checked === true)
    },
  },
  methods: {
    ...mapActions('searchReport', ['setStampReport']),
    ...mapMutations('searchReport', ['SET_CONTENT_REPORT', 'SET_CLEAR_CHECK']),
    downloadAPP() {
      window.open('https://download.farbun.com/')
    },
    // // 后退
    // b() {
    //   console.log('back--title')
    //   history.back()
    // },
    handleChangeTab(name, title) {
      console.log(name, title, this.active, 'name--title')
      if (this.active.toString()) {
        this.caselaelist = []
        this.panlipage = 1
        if (this.hatin) {
          this.caselaw(this.active, this.panliname, this.falvname, this.zhishiname, this.lawname)
        }
      }
    },
    closeReport() {
      this.showReport = false
    },
    handleCollect() {
      this.SET_CLEAR_CHECK(false)
      this.showReport = true
      // this.$router.push('/transform/searchReport')
    },
    // 从vuex里获取contentReports数据
    getContentReportsFromVUEX() {
      this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
      console.log(this.contentReports, '从vuex里获取contentReports数据')
      this.initContentReportData()
    },
    initContentReportData() {
      if (this.contentArr.length > 0 && this.id) {
        const index = this.contentArr.findIndex((item) => item.id === this.id)
        if (index === -1) {
          this.contentObj = {
            id: this.id,
            title: this.law.case_name || '',
            isLaw: true,
            arr: [],
            textArr: [],
          }
          this.contentArr.push(this.contentObj)
        } else {
          this.contentObj = this.contentArr[index]
        }
      } else {
        this.contentObj = {
          id: this.$route.params.id,
          title: this.law.case_name || '',
          isLaw: true,
          arr: [],
          textArr: [],
        }
        this.contentArr.push(this.contentObj)
      }
      console.log(this.contentObj, '==== initContentReportData =====')
      this.collectionArr = this.contentObj.arr
    },
    close(flag) {
      this.wangpan = false
      if (flag) {
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      } else {
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏',
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      }
    },
    getId(parentId) {
      if (!parentId) {
        return this.$notify('选择的文件夹数据存在问题')
      }
      this.$notify({
        background: 'green',
        duration: 1000,
        message: '收藏成功',
      })
      setTimeout(() => {
        document.querySelector('.van-notify').style.zIndex = '10000000'
      }, 100)
    },
    /**
     * 复制块级内容（以条为单位）
     * 格式为《标题》+条目名+空格+条内容
     * @param {object} payload
     */
    onCopyBlock(payload) {
      const { id, text } = payload
      const name = this.law.case_name || ''
      let str = ''
      const block = this.dataList.find((item) => item.id === id)
      if (block && Array.isArray(block.texts)) {
        const contents = block.texts.map((t) => t.text).join(' ')
        str = `${name} ${text} ${contents}`
      } else {
        str = `${name} ${text}`
      }
      const succ = copyTextToClipboard(str)
      if (succ) {
        this.$notify({ type: 'success', message: '本法条完整复制成功' })
      } else {
        this.$notify({ type: 'danger', message: '本法条完整复制失败' })
      }
    },
    // 侧边栏
    ShowFilter() {
      console.log('侧边连')
      const title = []
      this.caseTree.forEach((item) => {
        title.push(item.title)
      })
      this.TreeSelect.flag = true
      this.TreeSelect.data = this.caseTree
      this.TreeSelect.title = title
    },
    pushFilterCondition(payload) {
      const temp = []
      payload.map((item) => {
        temp.push(item.key)
      })
      const keys = [...new Set(temp)]
      const list = []
      keys.map((key) => {
        const obj = {
          key: key,
          value: [],
        }
        payload.map((item) => {
          if (obj.key === item.key) {
            obj.value.push(item.value[0])
          }
        })
        list.push(obj)
      })
      this.caselaelist = []
      this.panlipage = 1
      this.caselaw(this.active, this.panliname, this.falvname, this.zhishiname, this.lawname, list)
    },
    // 取消
    cancel() {
      this.panliCheckedAll = false
      this.panliChecked = false
      this.panliCheckedNum = 0
      this.caselaelist.map((item) => {
        item.checked = false
      })
      this.panliCheckDocIds = []
    },

    // 分享
    async share() {
      const newJudgeReports = []
      this.panliCheckList.forEach((item) => {
        const index = this.judgeReports.findIndex((val) => val.doc_id === item.doc_id)
        if (index === -1) {
          newJudgeReports.push(item)
        }
      })
      const arr = [...this.judgeReports, ...newJudgeReports]
      this.setStampReport({
        userId: this.userInfo.id,
        type: 'judgeReports',
        reportData: arr,
        callback: async () => {
          const data = await this.saveReport()
          this.reportId = data.id
          this.savedReportData = data
          const { name, id } = this.savedReportData
          if (name && id) {
            const ua = this.ua
            let shareUrl = `https://tool.farbun.com/transform/searchReport/${id}`
            if (this.$base === 'https://api.dev.farbun.com') {
              shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${this.reportId}`
            } else if (this.$base.includes('dev')) {
              shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${this.reportId}`
            } else {
              shareUrl = `https://tool.farbun.com/transform/searchReport/${this.reportId}`
            }
            if (ua === 'fb-flutter-Webview') {
              window.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: name,
                  description: '分享方法',
                  url: window.encodeURI(shareUrl),
                })
              )
            } else if (ua === this.$ua.a) {
              // eslint-disable-next-line no-undef
              AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
            } else if (ua === this.$ua.i) {
              window.webkit.messageHandlers.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: name,
                  url: window.encodeURI(shareUrl),
                })
              )
            } else {
              this.$notify('网页端暂不支持分享')
              this.copyLink(shareUrl)
            }
          } else {
            this.$notify('未能获取到需要的数据')
          }
        },
      })

      // console.log(this.judgeReports, '-------------')
    },

    // 下载
    downLoad() {
      if (this.panliCheckedNum > 1) {
        this.$notify('只支持单个下载')
      }
      this.caselaelist.map((item) => {
        if (item.checked) {
          this.$axios
            .get(`${this.$base}/document/legal/precedent/${item.doc_id}/file`)
            .then((res) => {
              if (res.data.code === '200') {
                // eslint-disable-next-line no-undef
                AND2JS.saveCaseFile(item.case_name, res.data.data.path)
              }
            })
            .catch((err) => {
              this.$toast('下载失败')
              Promise.reject(err)
            })
        }
      })
    },
    // 复制
    copy() {
      let text = ''
      this.caselaelist.map((item) => {
        if (item.checked) {
          text += item.case_name + '\n'
          if (item.court_name) {
            text += item.court_name + '/'
          }
          if (item.case_id) {
            text += item.case_id + '/'
          }
          text += item.pub_date
          text += '\n'
        }
      })
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({
        type: 'success',
        message: '内容已复制',
      })
    },

    // 加入收集箱
    joinCollectBox() {
      const newJudgeReports = []
      this.panliCheckList.forEach((item) => {
        const index = this.judgeReports.findIndex((val) => val.doc_id === item.doc_id)
        if (index === -1) {
          newJudgeReports.push(item)
        }
      })
      const arr = [...this.judgeReports, ...newJudgeReports]
      // console.log('加入收集箱', arr)
      if (this.panliCheckList && this.panliCheckList.length > 0) {
        this.$notify('已加入收集箱')
        this.setStampReport({
          userId: this.userInfo.id,
          type: 'judgeReports',
          reportData: arr,
        })
      }
    },

    next(val) {
      this.panlipage++
      this.caselaw(val, this.panliname, this.falvname, this.zhishiname, this.lawname)
    },
    // 单选
    changeCheck(index) {
      var panliCheckedAll = true
      var panliChecked = false
      var panliCheckedNum = 0
      this.panliCheckDocIds = []
      this.caselaelist.map((item) => {
        if (item.checked) {
          panliChecked = true
          panliCheckedNum++
          this.panliCheckDocIds.push(item.doc_id)
        } else {
          panliCheckedAll = false
        }
      })
      this.panliCheckedAll = panliCheckedAll
      this.panliChecked = panliChecked
      this.panliCheckedNum = panliCheckedNum
    },
    // 全选
    changeCheckAll(val) {
      this.panliCheckDocIds = []
      this.caselaelist.map((item) => {
        item.checked = val
        this.panliCheckDocIds.push(item.doc_id)
      })
    },
    // 点击判例法律只是出列表
    caselaw(val, panliname, falvname, zhishiname, lawname, filterCondition = []) {
      const index = lawname.indexOf('【')
      let Nnewname = ''
      if (index === -1) {
        Nnewname = lawname
      } else {
        Nnewname = lawname.substr(0, index)
      }
      lawname = Nnewname
      this.loading = true
      this.knowledge = true
      this.panliname = panliname
      this.falvname = falvname
      this.zhishiname = zhishiname
      this.lawname = lawname
      this.hatin = false
      if (val === 0) {
        const text = this.law.case_name + this.lawname
        const data = {}
        data.law = text
        if (filterCondition && filterCondition.length) {
          data.filter_condition = filterCondition
        }
        setTimeout(() => {
          this.$axios.post(`${this.$pydataBase}/api/law/relatedPrecedent/caseTreeSearch`, data).then((res) => {
            if (res.data.code === 200) {
              this.caseTree = res.data.result
            }
            this.$axios
              .post(`${this.$pydataBase}/api/law/relatedPrecedent/caseSearch`, {
                offset: this.panlipage,
                law: text,
                filter_condition: filterCondition,
              })
              .then((res) => {
                this.loading = false
                if (res.data.result.length === 0) {
                  this.$toast('暂无数据')
                  setTimeout(() => {
                    document.querySelector('.van-toast--text').style.zIndex = '10000000000000000000000'
                    document.querySelector('.van-toast--text').style.display = 'block'
                  })
                } else {
                  res.data.result.map((item) => {
                    item.checked = this.panliCheckedAll
                  })
                }
                this.caselaelist = this.caselaelist.concat(res.data.result)
                this.panliCheckedNum = 0
                this.caselaelist.forEach((item) => {
                  if (this.panliCheckDocIds && this.panliCheckDocIds.indexOf(item.doc_id) !== -1) {
                    item.checked = true
                    this.panliCheckedNum++
                  } else {
                    item.checked = false
                  }
                })
                if (this.panliCheckedNum === 0) {
                  this.panliCheckedAll = false
                  this.panliChecked = false
                } else {
                  this.panliChecked = true
                }
              })
              .catch(() => {
                this.$toast('暂无数据')
                this.loading = false
              })
          })
        })
      } else if (val === 1) {
        setTimeout(() => {
          this.$axios.get(`${this.$pydataBase}/api/nvi/law/detail/quote_ledge?title=${this.law.case_name}&name=${this.lawname}&offset=${this.panlipage}`).then((res) => {
            this.loading = false
            if (res.data.result.data.length === 0) {
              this.$toast('暂无数据')
              setTimeout(() => {
                document.querySelector('.van-toast--text').style.zIndex = '10000000000000000000000'
                document.querySelector('.van-toast--text').style.display = 'block'
              })
            }
            this.caselaelist = this.caselaelist.concat(res.data.result.data)
          })
        })
      } else if (val === 2) {
        setTimeout(() => {
          this.$axios.get(`${this.$pydataBase}/api/nvi/law/detail/quote_ledge?title=${this.law.case_name}&name=${this.lawname}&offset=${this.panlipage}`).then((res) => {
            this.loading = false
            if (res.data.result.data.length === 0) {
              this.$toast('暂无数据')
              setTimeout(() => {
                document.querySelector('.van-toast--text').style.zIndex = '10000000000000000000000'
                document.querySelector('.van-toast--text').style.display = 'block'
              })
            }
            this.caselaelist = this.caselaelist.concat(res.data.result.data)
          })
        })
      }
      setTimeout(() => {
        this.hatin = true
      })
    },
    // type: type,
    //     name: this.source.name,
    //     reflaw_num: this.source.reflaw_num,
    //     cite_ledge_num: this.source.cite_ledge_num
    quoteClickEvent(data) {
      this.active = data.type
      if (this.active.toString()) {
        this.caselaelist = []
        this.panlipage = 1
        if (this.hatin) {
          this.caselaw(data.type, data.reflaw_num, 0, data.cite_ledge_num, data.name)
        }
      }
    },
    clickClipDataEvent(data) {
      this.clip_data = JSON.parse(data)
      this.clipDataShow = true
    },
    // 非会员限制访问不完整的数据
    filterDataList(content) {
      const { isMiniVip } = this.$route.query

      const dataList = deepCopy(content)
      if (this.$route.query.id) {
        if (isMiniVip) {
          return dataList
        }
      } else {
        // const isVip = this.userinfo && this.userinfo.memberStatus === 1
        // const lawid = this.$route.params && this.$route.params.id
        // if (isVip || this.isValid || lawid) {
        console.log(this.userinfo, 'infop')
        if (this.userinfo.memberStatus === 1 || this.userinfo.restNum > 0) {
          return dataList
        } else {
          console.log('nomember')
          if (dataList && dataList.length > 5) {
            return dataList.slice(0, 3)
          } else if (dataList && dataList.length <= 5 && dataList.length >= 3) {
            return dataList.slice(0, 2)
          } else {
            return dataList.slice(0, 1)
          }
        }
      }
    },
    // 获取法律数据
    async fetchLaw(id, topic) {
      this.isLoading = true
      let url = ''
      if (!topic || topic === undefined) {
        url = `${this.$pydataBase}/api/nvi/law/detail?law_id=${id}`
      } else {
        url = `${this.$pydataBase}/api/nvi/law/detail?law_id=${id}&title=${topic}`
      }

      try {
        const { data } = await this.$axios(url)
        // const additionData = await this.$axios(`${this.$pydataBase}/api/nvi/law/detailExtendRelevancy?law_id=${id}`)
        const hasResult = data && data.result
        if (!hasResult) {
          this.resetComponentData()
          return
        }
        this.isError = false
        const re = deepCopy(data.result)
        const { catalog, dataList } = this.traverseLawData(re)
        this.law = re
        this.catalog = catalog
        // dataList.forEach((dataListItem) => {
        //   // const addition = additionData.data.data.find((addItem) => dataListItem.name.split('【')[0].trim() === addItem[0].name)
        //   // if (addition) {
        //   //   dataListItem.addition_data = addition
        //   // }
        //   if (additionData && additionData.data && additionData.data.data) {
        //     const addition = additionData.data.data.find((addItem) => dataListItem.name.split('【')[0].trim() === addItem[0].name)
        //     if (addition) {
        //       dataListItem.addition_data = addition
        //     }
        //   }
        // })
        this.dataList = dataList
        this.isLoading = false
        this.getContentReportsFromVUEX()
        if (this.dataList && this.dataList.length) {
          this.handleCheckedResource()
        }
      } catch (err) {
        this.isError = true
        this.isLoading = false
        console.log(err)
      }
    },
    // 对数据进行处理
    traverseLawData(re) {
      const catalog = makeCatalogToTree(deepCopy(re.catlog_data))
      // const content = deepCopy(re.content)
      const content = this.filterDataList(re.content)
      /**
       * 接口返回的法律数据 条目和内容带有父子级关系
       * 为了简化数据操作 将数据排成数组
       */
      const f = Array.isArray(content) ? makeTreeNodeToFlat(content) : []
      // 将标题和文章属性取出来作为第一条数据
      const { case_name: name, more_info: attr } = re
      const firstItem = {
        name,
        $name: '',
        attr,
        level: 0,
        id: this.id,
        type: '标题',
        checked: false,
      }
      const dataList = [firstItem, ...addCheckStateForText(f)]
      return { catalog, dataList }
    },
    // 处理导航组件事件
    handleNavbarEvent(evtName) {
      switch (evtName) {
        case 'BACK':
          if (this.$route.name) {
            this.$emit('onBack', false)
          }
          this.$emit('back')
          break
        case 'TOGGLE':
          this.isCleanMode = !this.isCleanMode
          break
        case 'FAV':
          this.dealCollect() // 收藏方法
          break
        case 'SHARE':
          this.dealShare() // 分享方法
          console.log('处理导航组件事件  SHARE')
          break
      }
    },
    // 分享方法
    dealShare() {
      const ua = navigator.userAgent
      const shareUrl = `${window.location.origin}/search/law/${this.id}`
      const name = this.lawName
      if (ua === 'fb-flutter-Webview') {
        // flutter方法
        window.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            description: '律呗法律大数据',
            url: window.encodeURI(shareUrl),
          })
        )
      } else if (ua === this.$ua.a) {
        // 安卓
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(name, '律呗法律大数据', window.encodeURI(shareUrl))
      } else if (ua === this.$ua.i) {
        // 苹果
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            description: '律呗法律大数据',
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.copyText(shareUrl)
        this.$notify('网页端暂不支持分享,已复制链接请粘贴给需要分享的人')
      }
    },
    // 复制到粘贴板上
    copyText(url) {
      let text = url
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
    },
    // 收藏方法
    dealCollect() {
      this.wangpan = true
      console.log(this.wangpan)
    },
    // 获取法律数据
    async fetchPopupLaw(id) {
      this.lawPopupVisible = true
      this.lawPopupDataList = []
      this.lawPopupCatalog = []
      this.lawPopupLoading = true
      const url = `${this.$pydataBase}/api/nvi/law/detail?law_id=${id}`
      try {
        const { data } = await this.$axios(url)
        const hasResult = data && data.result
        if (!hasResult) {
          this.resetComponentData()
          return
        }
        const re = deepCopy(data.result)
        const { dataList } = this.traverseLawData(re)
        this.lawPopupCatalog = re.catlog_data || []
        this.lawPopupDataList = dataList
        this.lawPopupLoading = false
      } catch (err) {
        this.lawPopupLoading = false
        console.log(err)
      }
    },
    // 弹出法条
    onPopLaw({ id, toCatalog }) {
      this.lawPopupToCatalog = toCatalog
      this.fetchPopupLaw(id)
    },
    // 重置组件数据
    resetComponentData() {
      Object.assign(this.$data, this.$options.data())
    },
    // 尝试重新请求法律数据
    retryFetchLaw() {
      if (this.id) {
        this.fetchLaw(this.id)
      } else {
        this.$notify('参数错误')
      }
    },
  },
  mounted() {},
  created() {},
  watch: {
    entry: {
      immediate: true,
      handler(nv) {
        if (nv && JSON.stringify(nv) !== '{}') {
          const { id, catalog, topic } = nv
          this.id = id
          this.toCatalog = catalog
          this.topic = topic
          this.dataList = []
          this.fetchLaw(id, topic)
        } else {
          this.resetComponentData()
        }
      },
    },
    // contentReports() {
    //   this.getContentReportsFromVUEX()
    //   if (this.dataList && this.dataList.length) {
    //     this.handleCheckedResource()
    //   }
    // }
  },
}
</script>

<style scoped lang="stylus">
.law-citation
  width 100%
  height 100%
  position fixed
  z-index 10000
  top 0px
  left 0px
  overflow auto
  background #FFF
  padding-top 44px
  & .fb-header
    position fixed
    z-index 100
    top 0px
    left 0px
    width 100%
.panli
  width 90%
  margin 0px auto
  .title
    font-size 15px
    color #333
    font-weight bold
    margin-top 10px
  .contet
    border-bottom 1px solid #ccc
    div:nth-child(1)
      font-size 15px
      color #333
      font-weight bold
      margin-top 10px
    div:nth-child(2)
      font-size 14px
      color #ccc
      margin-top 10px
      margin-bottom 10px
.law-detail
  width 100vw
  & .nav-bar
    height 44px
    position fixed
    width 100%
    background #fff
    top 0
    left 0
    z-index 2
    >>> .van-nav-bar__title
      max-width 80%
      text-align left
      margin-left 50px
  & .content
    height calc(100vh - 44px)
    margin-top 44px
    overflow-y auto
    &.peek
      >>> .float-actions
        visibility hidden
    & .van-loading
      margin-top 20px
    & .kw-panel
      position fixed
      bottom -1px
      left 0
      width 100%
      & .kw-list
        padding 0 10px
        margin-bottom 2px
        & .van-tag
          margin 5px 10px 0 0
          &.kw-tag
            background #ffee00
            color #333
          &.van-tag--plain
            background #999
            color #fff
      & .kw-nav
        border-top 1px solid #eee
        display flex
        background #fff
        width 100%
        justify-content space-between
        padding 5px
        font-size 15px
        height 44px
        align-items center
        & .act
          width 108px
          display flex
          color #1989fa
          height 44px
          & div
            flex 1
            border-right 1px solid #eee
            height 100%
            display flex
            justify-content center
            align-items center
        & .nav
          88px
          & .van-icon
            font-size 38px
    & .download-panel
      position fixed
      bottom -1px
      left 0
      width 100%
      height 40px
      background #ff9a01
      line-height 40px
      padding 0 10px
      // border-radius 0  0 20px 20px
      color #000
      font-size 14px
      box-sizing border-box
      & .download-text
        color #409eff
        text-decoration underline
>>> .pop-content
  height calc(86vh)
  overflow-y auto
  & .van-loading
    margin-top 20px
  & .law-scroller .float-actions .query
    display none
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
</style>
