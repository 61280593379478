import { addClass, removeClass, hasClass } from '@/utils/dom'
import Vue from 'vue'
export default {
  data() {
    return {
      queryPopupVisible: false,
      // 是否在关键词上下导航模式
      isPeeking: false,
      kwList: [],
      // 用于导航的关键词列表
      navList: [],
      // 高亮结果
      hlResult: [],
      // id排序字段
      hlSort: [],
      curHlIndex: -1,
      activeClassName: 'active'
    }
  },
  computed: {
    hlCurrentNum() {
      return this.curHlIndex + 1
    },
    // 使用到的结果列表
    usedResult() {
      return this.hlResult.filter(item => {
        const { kw } = item
        return this.navList.includes(kw)
      })
    },
    hlTotalNum() {
      return this.usedResult.length
    },
    navSort() {
      return this.hlSort.filter(m => {
        return this.usedResult.find(item => item.markId === m)
      })
    }
  },
  methods: {
    // 打开页内检索弹窗
    openInnerQueryPopup() {
      this.queryPopupVisible = true
      setTimeout(() => {
        this.autoFocus()
      }, 1000)
    },
    // 关闭页内检索弹框
    cancelInnerQueryPopup() {
      this.kwList = []
      this.exitPeeking()
      this.queryPopupVisible = false
    },
    autoFocus() {
      this.$refs.queryRef && this.$refs.queryRef.focus()
    },
    // 查看高亮结果
    viewHighlight(arr) {
      if (!arr || arr.length === 0) {
        return this.cancelInnerQueryPopup()
      }
      this.kwList = arr
      this.navList = arr
      this.handleInnerQuery(this.navList)
      this.queryPopupVisible = false
      this.isPeeking = true
    },
    /*
     ** 处理页内检索
     ** 使用纯文本正则替换的方式实现关键词两边添加html标签以实现关键词高亮
     ** 由于执行替换时存在顺序 故多个关键词之间如果有文字重叠则会出现结果不准确的问题
     */
    handleInnerQuery(arr) {
      let re = []
      let sortIds = []
      const num = arr.map(elem => 0)
      this.dataList.forEach((block, blockIndex) => {
        const blockRe = []
        const { name, texts, id } = block
        let withMark = name
        arr.forEach((kw, kwIndex) => {
          const nameArr = withMark.split(kw)
          const len = nameArr.length
          if (len > 1) {
            withMark = nameArr.reduce((str, cur) => {
              num[kwIndex]++
              const markId = `${kw}-${num[kwIndex]}`
              const hl = {
                blockIndex,
                blockId: id,
                kwIndex,
                kw,
                markId,
                sort: num[kwIndex]
              }
              blockRe.push(hl)
              return `${str}<mark id='${markId}'>${kw}</mark>` + cur
            })
          }
        })
        block.$name = withMark

        if (texts) {
          texts.forEach(textObj => {
            const { text } = textObj
            let withMark = text
            arr.forEach((kw, kwIndex) => {
              const textArr = withMark.split(kw)
              const len = textArr.length
              if (len > 1) {
                withMark = textArr.reduce((str, cur) => {
                  num[kwIndex]++
                  const markId = `${kw}-${num[kwIndex]}`
                  const hl = {
                    blockIndex,
                    blockId: id,
                    markId,
                    kwIndex,
                    kw,
                    sort: num[kwIndex]
                  }
                  blockRe.push(hl)
                  return `${str}<mark id='${markId}'>${kw}</mark>` + cur
                })
              }
            })
            textObj.$text = withMark
          })
        }
        // 通过渲染成DOM片段来实现排序
        let frag = document.createDocumentFragment()
        const span = document.createElement('span')
        const getTextStr = arr => {
          return arr.map(o => o.$text).reduce((prev, cur) => prev + cur, '')
        }
        const htmlStr = `${block.$name}${
          block.texts ? getTextStr(block.texts) : ''
        }`
        span.innerHTML = htmlStr
        frag.appendChild(span)
        const list = frag.querySelectorAll('mark')
        if (list.length) {
          const arr = Array.from(list)
          const ids = arr.map(node => node.id)
          re = re.concat(blockRe)
          sortIds = sortIds.concat(ids)
        }
        frag = null
      })
      this.hlResult = re
      this.hlSort = sortIds
    },
    // 关键词导航
    navKw(dire) {
      if (this.navList.length === 0) return
      dire === 'down' ? this.curHlIndex++ : this.curHlIndex--
      const max = this.navSort.length - 1
      const min = 0
      if (this.curHlIndex < min) {
        this.curHlIndex = max
      } else if (this.curHlIndex > max) {
        this.curHlIndex = min
      }
      this.curMarkId = this.navSort[this.curHlIndex]
      this.scrollToKw(this.curMarkId)
    },
    // 滚动至指定关键词
    scrollToKw(markId) {
      const hl = this.hlResult.find(h => h.markId === markId)
      if (!hl) return
      const { blockIndex } = hl
      const elem = document.getElementById(markId)
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' })
        this.markActive()
      } else {
        this.$refs.lawScrollerRef &&
          this.$refs.lawScrollerRef.scrollToIndex(blockIndex)
        setTimeout(() => {
          if (!this.curMarkId) return
          const elem = document.getElementById(this.curMarkId)
          elem && elem.scrollIntoView({ behavior: 'smooth' })
          this.markActive()
        }, 110)
      }
    },
    // 将高亮的关键词添加 激活样式
    markActive() {
      const targetId = this.curMarkId
      const markers = document.querySelectorAll('mark')
      if (markers && markers.length) {
        markers.forEach(m => {
          removeClass(m, this.activeClassName)
        })
      }
      const elem = targetId && document.getElementById(targetId)
      addClass(elem, this.activeClassName)
      /**
       * 页面在滚动的过程中 虚拟列表可能会触发数据更新导致DOM变化从而导致激活样式失效
       * 在一定的间隔时间之后检查元素是否有激活样式 没有则再次添加一下即可
       * */
      setTimeout(() => {
        const targetId = this.curMarkId
        if (!targetId) return
        const elem = targetId && document.getElementById(targetId)
        if (elem && hasClass(elem, this.activeClassName)) return
        addClass(elem, this.activeClassName)
      }, 1001)
    },
    // 判断是否用作导航
    isNavKw(kw) {
      return this.navList.includes(kw)
    },
    // 切换用作导航
    toggleUse(kw) {
      if (this.isNavKw(kw)) {
        this.navList = this.navList.filter(item => item !== kw)
      } else {
        this.navList = [...this.navList, kw]
      }
      if (this.navList.length) {
        this.handleInnerQuery(this.navList)
        this.adjustCurHlIndex()
      } else {
        this.removeHL()
        this.curHlIndex = -1
      }
    },
    // 调整当前激活的关键词下标 防止越界
    adjustCurHlIndex() {
      if (this.curHlIndex > this.navSort.length - 1) {
        this.curHlIndex = this.navSort.length - 1
        this.curMarkId = this.navSort[this.curHlIndex]
        this.scrollToKw(this.curMarkId)
      }
    },
    // 退出
    exitPeeking() {
      this.isPeeking = false
      this.removeHL()
      this.resetData()
    },
    // 重新设置数据
    resetData() {
      this.hlResult = []
      this.hlSort = []
      this.kwList = []
      this.navList = []
      this.curHlIndex = -1
    },
    // 移除高亮
    removeHL() {
      this.dataList.forEach(block => {
        block.$name = ''
        if (block.texts) {
          block.texts.forEach(textObj => {
            textObj.$text = ''
          })
        }
      })
    },
    /** 法条内容勾选
     * @param type{string} ['TITLE', 'TEXT'] TITLE-标题 TEXT-正文
     * */
    onLawCheck(payload) {
      // TODO 将勾选的数据与收集箱联动
      const { id, type } = payload
      const targetItem = this.dataList.find(item => item.id === id)
      if (targetItem) {
        if (type === 'TITLE') {
          const ch = !targetItem.checked
          targetItem.checked = ch
          if (Array.isArray(targetItem.texts) && targetItem.texts.length) {
            const ts = targetItem.texts.map(t => ({ ...t, checked: ch }))
            targetItem.texts = ts
          }
        } else if (type === 'TEXT') {
          const { index } = payload
          const t = targetItem.texts[index]
          const ch = !t.checked
          Vue.set(t, 'checked', ch)
          if (Array.isArray(targetItem.texts) && targetItem.texts.length) {
            const checkedTS = targetItem.texts.filter(v => v.checked)
            if (checkedTS.length === 0 && !ch) {
              // 清空父级选中
              targetItem.checked = false
            } else if (checkedTS.length === targetItem.texts.length && ch) {
              // 设置父级选中
              targetItem.checked = true
            }
          }
        }
        const index = this.collectionArr.findIndex(v => v.id === targetItem.id)
        const collectObj = {
          name: targetItem.name,
          id: targetItem.id,
          type: 'TITLE',
          arr: []
        }
        if (index === -1) {
          // 未收藏过这条数据
          // if (targetItem.checked) {
          if (Array.isArray(targetItem.texts) && targetItem.texts.length) {
            targetItem.texts.forEach((v, i) => {
              if (v.checked) {
                collectObj.arr.push({
                  index: i,
                  text: v.text,
                  type: 'TEXT'
                })
              }
            })
          }
          this.collectionArr.push(collectObj)
          // }
        } else {
          // 收藏过这条数据
          // 加入父项及下面的选中的子项
          if (Array.isArray(targetItem.texts) && targetItem.texts.length) {
            targetItem.texts.forEach((v, i) => {
              if (v.checked) {
                collectObj.arr.push({ text: v.text, index: i, type: 'TEXT' })
              }
            })
          }
          if (!targetItem.checked && collectObj.arr.length === 0) {
            // 删除父项及下面的子项
            this.collectionArr.splice(index, 1)
          } else {
            this.collectionArr[index] = collectObj
          }
        }
        this.contentObj.arr = this.collectionArr
        this.contentObj.title = this.contentObj.title || this.law.case_name
        this.contentArr.forEach((item, index) => {
          if (item.id === this.contentObj.id) {
            this.contentArr[index] = this.contentObj
          }
        })
        const arr = this.contentArr.filter(item => {
          return item.arr.length + item.textArr.length > 0
        })
        if (this.userInfo && this.userInfo.id) {
          this.setStampReport({
            userId: this.userInfo.id,
            type: 'contentReports',
            reportData: JSON.parse(JSON.stringify(arr)) || []
          })
        } else {
          this.SET_CONTENT_REPORT(JSON.parse(JSON.stringify(arr)) || [])
        }
      }
    },
    // 修改选中状态数据
    handleCheckedResource() {
      const data = this.dataList
      data.forEach((item, i) => {
        const targetIndex = this.collectionArr.findIndex(v => v.id === item.id)
        if (targetIndex !== -1) {
          const arr = this.collectionArr[targetIndex].arr || []
          if (Array.isArray(item.texts) && item.texts.length) {
            item.texts.forEach(text => {
              text.checked = false
            })
            arr.forEach(v => {
              this.$set(item.texts[v.index], 'checked', true)
            })
            if (arr.length === item.texts.length) {
              this.$set(data[i], 'checked', true)
            }
          }
        } else {
          this.$set(data[i], 'checked', false)
          if (Array.isArray(item.texts) && item.texts.length) {
            item.texts.forEach(text => {
              text.checked = false
            })
          }
        }
      })
      // this.dataList.fprEach(v=>{})
    }
  }
}
