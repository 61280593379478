<template>
  <div class="menu-item">
    <div class="title">
      <span @click="navTo(data.name)">
        <span class="left">{{ fmtNodeLabel(data) }}</span>
      </span>
      <span
        v-if="data.value && data.value.length"
        class="iconfont icon-child-off"
        @click="toggle"
      ></span>
    </div>
    <div>
      <template v-if="data.value && data.value.length">
        <tree-item
          v-for="(item, index) of data.value"
          :key="index"
          :data="item"
          v-show="expand"
        ></tree-item>
      </template>
    </div>
  </div>
</template>
<script>
import Emitter from '@/mixins/emitter'
export default {
  name: 'tree-item',
  props: ['data'],
  mixins: [Emitter],
  data() {
    return {
      right: false,
      expand: false,
      showopen: false,
      e: 0 // 下拉框的收起和打开，0收起
    }
  },
  mounted() {
    if (this.data.type) {
      this.right = true
    }
  },
  methods: {
    navTo(name) {
      this.dispatch('law-scroller', 'on-catalog-select', { name })
      console.log('name', name)
    },
    toggle() {
      this.expand = !this.expand
    },
    fmtNodeLabel(data) {
      if (typeof data === 'string') return data
      if (!data.range) return data.name
      const {
        name,
        range: { from, to }
      } = data
      return `${name} (${from}-${to})`
    },
    fmtName(name) {
      const index = name.indexOf('【')
      let newName = ''
      if (index === -1) {
        newName = name
      } else {
        newName = name.substr(0, index)
      }
      return newName
    }
  }
}
</script>
<style lang="stylus" scoped>
.menu-item
  width 100%
  padding 8px
  .title
    font-size 14px
    font-family PingFangSC-Light
    font-weight bold
    color rgba(0, 0, 0, 1)
    // line-height 15px
    a
      color rgba(0, 0, 0, 1)
    .left
      display inline-block
      width 80%
      // border 1px solid red
    .icon-child-off
      float right
      margin-right 0px
</style>
