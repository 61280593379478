<template>
  <van-nav-bar :title="title" left-arrow @click-left="emitEvent('BACK')">
    <template #right>
      <!-- <span class="mode-text" @click="emitEvent('TOGGLE')">{{ modeText }}</span> -->
      <van-popover v-model="showPopover" trigger="click" theme="dark" placement="bottom-end" :actions="actions" @select="onSelect">
        <template #reference>
          <i class="iconfont icon-caozuo"></i>
        </template>
      </van-popover>
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: 'nav-bar',
  props: {
    title: {
      type: String,
      default: (_) => '',
    },
    // 简洁模式
    isCleanMode: {
      type: Boolean,
      default: (_) => true,
    },
  },
  data() {
    return {
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: '收藏', type: 'FAV' },
        { text: '分享', type: 'SHARE' },
        // { text: '下载', type: 'DOWNLOAD' }
      ],
    }
  },
  computed: {
    modeText() {
      return this.isCleanMode ? '显示扩展' : '只看原文'
    },
  },
  methods: {
    onSelect(action) {
      this.emitEvent(action.type)
    },
    // emit事件
    emitEvent(name) {
      this.$emit('menuEvent', name)
    },
  },
  mounted() {},
  created() {},
}
</script>

<style scoped lang="stylus">
.mode-text
  margin 0 8px
  color #ff9a01
  font-size 16px
.icon-menu
  font-size 20px
</style>
