<template>
  <div class="clip-data">
    <!--
    <div
      class="clip-data-box"
      v-for="(item, index) in clip_data"
      :key="index"
      v-show="item.values && item.values.length > 0"
    >
      <div class="clip-data-box-title">{{ item.title }}</div>
      <div class="clip-data-box-content">
        <div v-for="(childItem, i) in item.values" :key="i">
          <div
            v-if="typeof childItem === 'string'"
            class="clip-data-box-content-text"
            v-html="childItem"
          ></div>
          <div v-else>
            <div class="clip-data-box-content-title">
              {{ childItem.law_title }}
            </div>
            <div
              class="clip-data-box-content-text"
              v-if="typeof childItem.text === 'string'"
              v-html="childItem.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
    -->
    <van-tabs v-model="activeName">
      <van-tab
        v-for="(item, index) in clip_data"
        :key="index"
        :title="item.title"
        :name="item.title"
        class="clip-data-box"
      >
        <div class="clip-data-box-content">
          <div v-for="(childItem, i) in item.values" :key="i">
            <div
              v-if="typeof childItem === 'string'"
              class="clip-data-box-content-text"
              v-html="childItem"
            ></div>
            <div v-else>
              <div class="clip-data-box-content-title">
                {{ childItem.law_title }}
              </div>
              <div
                class="clip-data-box-content-text"
                v-if="typeof childItem.text === 'string'"
                v-html="childItem.text"
              ></div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'clip-data',
  props: {
    clip_data: {
      type: Array
    }
  },
  data() {
    return {
      activeName: ''
    }
  },
  methods: {},
  mounted() {
    if (this.clip_data && this.clip_data.length) {
      this.activeName = this.clip_data[0].title || ''
    }
  },
  created() {}
}
</script>

<style scoped lang="stylus">
.clip-data
  width 100%
  height 100%
  overflow auto
  padding 20px 10px
  box-sizing border-box
  & .clip-data-box
    & .clip-data-box-title
      font-size 12px
      color #FF9900
    & .clip-data-box-content
      padding-top 10px
      & .clip-data-box-content-title
        color #000
        font-size 12px
        font-weight bold
        margin-bottom 5px
        cursor pointer
      & .clip-data-box-content-text
        font-size 14px
        cursor pointer
        color #666666
        margin-bottom 5px
        width 100%
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
</style>
