var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inner-query" }, [
    _c(
      "div",
      { staticClass: "input-field" },
      [
        _c(
          "van-search",
          {
            ref: "inputRef",
            attrs: {
              placeholder: "请输入搜索关键词",
              "show-action": "",
              shape: "round",
            },
            nativeOn: {
              keyup: function ($event) {
                return _vm.hanldeKey("SPACE")
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleKey("ENTER")
              },
            },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "input",
            },
          },
          [
            _c("div", { attrs: { slot: "action" }, slot: "action" }, [
              _c("span", { on: { click: _vm.addKw } }, [_vm._v("查找")]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.kwList, function (kw) {
        return _c("div", { key: kw, staticClass: "kw-item" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(kw))]),
          _c("div", { staticClass: "status" }, [
            _vm._v("共 " + _vm._s(_vm.calcResultByKw(kw)) + " 条"),
          ]),
          _c(
            "div",
            {
              staticClass: "delete",
              on: {
                click: function ($event) {
                  return _vm.deleteMe(kw)
                },
              },
            },
            [_c("i", { staticClass: "iconfont icon-delete" })]
          ),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "van-button",
          { attrs: { size: "normal" }, on: { click: _vm.cancel } },
          [_vm._v("退出")]
        ),
        _c(
          "van-button",
          {
            staticClass: "flex-1",
            attrs: { size: "normal", type: "info" },
            on: { click: _vm.viewResult },
          },
          [_vm._v("查看")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }