var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "law-text",
      attrs: { "data-id": _vm.id, "data-index": _vm.index },
    },
    [
      _c(
        "span",
        { staticClass: "text-container" },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.htmlString) } }),
          _c("after-tip", { attrs: { source: _vm.afterTipData } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }