var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attrs" },
    _vm._l(_vm.attr, function (a) {
      return _c("span", { key: a.name, staticClass: "attr" }, [
        _vm._v(_vm._s(_vm.fmtLabel(a))),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }