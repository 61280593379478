<template>
  <span class="after-tip">
    <i
      class="iconfont"
      :class="`icon-${act.icon}`"
      v-for="act in icons"
      :key="act.name"
      :title="act.label"
      @click="onIconClick(act.name)"
    ></i>
  </span>
</template>

<script>
import Emitter from '@/mixins/emitter'
import { copyTextToClipboard } from '@/utils/assist'

const actions = [
  { name: 'COPY', icon: 'copy-text', label: '复制本段内容' },
  { name: 'COPYBLOCK', icon: 'copy-block', label: '复制本条全部内容' },
  { name: 'SEND', icon: 'send', label: '跳转' }
]

export default {
  name: 'after-tip',
  props: {
    // 数据源 { lawId, text }
    source: {
      type: Object
    },
    // 操作图标 ['COPY', 'COPYALL', 'SEND']
    options: {
      type: Array,
      default: _ => ['COPY']
    }
  },
  mixins: [Emitter],
  data() {
    return {}
  },
  computed: {
    // 文本数据
    text() {
      return (
        (this.source.text && this.source.text.replace(/<[^>]+>/g, '')) || ''
      )
    },
    // 有某项id数据用于跳转
    hasId() {
      return !!this.source.lawId
    },
    // 显示的图标操作
    icons() {
      return actions.filter(act => this.options.includes(act.name))
    }
  },
  methods: {
    onIconClick(IconType) {
      switch (IconType) {
        case 'COPY':
          this.copyText(this.text)
          break
        case 'COPYBLOCK':
          this.copyBlock()
          break
      }
    },
    copyText(text) {
      const succ = copyTextToClipboard(text)
      if (succ) {
        this.$notify({ type: 'success', message: '本段文字复制成功' })
      } else {
        this.$notify({ type: 'danger', message: '本段文字复制失败' })
      }
    },
    copyBlock() {
      this.dispatch('law-scroller', 'on-copy-block', this.source)
    }
  },
  mounted() {},
  created() {}
}
</script>

<style scoped lang="stylus"></style>
