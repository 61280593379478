var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasContent
    ? _c(
        "div",
        { staticClass: "law-block", attrs: { "data-id": _vm.id, id: _vm.id } },
        [
          _vm.titleShouldRender
            ? [
                _c("law-title", {
                  attrs: {
                    isCleanMode: _vm.isCleanMode,
                    source: _vm.source,
                    hasTexts: _vm.hasTexts,
                  },
                }),
              ]
            : _vm._e(),
          _vm.hasAttr
            ? [_c("law-attr", { attrs: { attr: _vm.source.attr } })]
            : _vm._e(),
          _vm.hasTexts
            ? _vm._l(_vm.texts, function (text, i) {
                return _c("law-text", {
                  key: `${_vm.id}-${i}`,
                  attrs: { index: i, item: text, isCleanMode: _vm.isCleanMode },
                })
              })
            : _vm._e(),
          _vm.hasRele
            ? [_c("law-rele", { attrs: { source: _vm.source } })]
            : _vm._e(),
          _vm.hasAdditions
            ? [
                _c(
                  "span",
                  {
                    staticClass: "addition-popup-trigger",
                    style: _vm.additionPopupTriggerStyle,
                    on: { click: _vm.emitOpenAdditionPopup },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "icon",
                        attrs: {
                          t: "1646731032296",
                          viewBox: "0 0 1024 1024",
                          version: "1.1",
                          xmlns: "http://www.w3.org/2000/svg",
                          "p-id": "2171",
                          width: "20",
                          height: "20",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123.392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z",
                            "p-id": "2172",
                            fill: "#ff9a01",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }