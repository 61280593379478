var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-item" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "span",
        {
          on: {
            click: function ($event) {
              return _vm.navTo(_vm.data.name)
            },
          },
        },
        [
          _c("span", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.fmtNodeLabel(_vm.data))),
          ]),
        ]
      ),
      _vm.data.value && _vm.data.value.length
        ? _c("span", {
            staticClass: "iconfont icon-child-off",
            on: { click: _vm.toggle },
          })
        : _vm._e(),
    ]),
    _c(
      "div",
      [
        _vm.data.value && _vm.data.value.length
          ? _vm._l(_vm.data.value, function (item, index) {
              return _c("tree-item", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expand,
                    expression: "expand",
                  },
                ],
                key: index,
                attrs: { data: item },
              })
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }