var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-scroller" },
    [
      _c(
        "virtual-list",
        {
          ref: "vlRef",
          staticClass: "scroller",
          attrs: {
            "data-key": "id",
            "extra-props": { isCleanMode: _vm.isCleanMode },
            "data-sources": _vm.dataList,
            "data-component": _vm.itemComponent,
          },
          on: { scroll: _vm.handleScroll },
        },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "mode-text", on: { click: _vm.emitToggleMode } },
              [_vm._v(" " + _vm._s(_vm.modeText) + " ")]
            ),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("bottom-tip", {
                attrs: {
                  visible: _vm.bottomTipVisible,
                  showLimit: _vm.showLimit,
                },
                on: {
                  "update:showLimit": function ($event) {
                    _vm.showLimit = $event
                  },
                  "update:show-limit": function ($event) {
                    _vm.showLimit = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "van-popup",
        {
          style: { height: "70%" },
          attrs: {
            "close-icon-position": "top-left",
            "get-container": "#outter",
            position: "bottom",
          },
          model: {
            value: _vm.additionPopupVisible,
            callback: function ($$v) {
              _vm.additionPopupVisible = $$v
            },
            expression: "additionPopupVisible",
          },
        },
        [
          _c("law-addition", {
            attrs: { addition_data: _vm.additions, id: _vm.additionId },
            on: { popLaw: _vm.emitPopLaw },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%" },
          attrs: { "get-container": "#outter", position: "right" },
          model: {
            value: _vm.menuVisible,
            callback: function ($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible",
          },
        },
        [_c("law-menu", { attrs: { catalog: _vm.catalog } })],
        1
      ),
      _c("fb-nonmember", {
        attrs: { showLimit: _vm.showLimit },
        on: {
          "update:showLimit": function ($event) {
            _vm.showLimit = $event
          },
          "update:show-limit": function ($event) {
            _vm.showLimit = $event
          },
        },
      }),
      _c("div", { staticClass: "float-actions" }, [
        _c("div", { staticClass: "menu", on: { click: _vm.toggleMenu } }, [
          _c("i", { staticClass: "iconfont icon-liebiao1" }),
        ]),
        _vm.reportCount > 0
          ? _c(
              "div",
              { staticClass: "collect", on: { click: _vm.emitCollect } },
              [
                _c("transition", { attrs: { name: "slide-fade" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAdd,
                          expression: "showAdd",
                        },
                      ],
                      staticClass: "badge-box",
                    },
                    [_vm._v(" +1 ")]
                  ),
                ]),
                _c("transition", { attrs: { name: "slide-fade" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showReduce,
                          expression: "showReduce",
                        },
                      ],
                      staticClass: "badge-box",
                    },
                    [_vm._v(" -1 ")]
                  ),
                ]),
                _c("span", { staticClass: "collect-text" }, [_vm._v("集")]),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(this.reportCount)),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "query", on: { click: _vm.emitOpenInnerQuery } },
          [_c("i", { staticClass: "iconfont icon-search" })]
        ),
        _c("div", { staticClass: "to-top", on: { click: _vm.scrollToTop } }, [
          _c("i", { staticClass: "iconfont icon-cc-top" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }