var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "title", class: _vm.titleClassName }, [
    _vm.isTypeTiao
      ? _c("i", {
          staticClass: "fake-check-box",
          class: _vm.checkBoxClassName,
          style: _vm.cleanModeVisibleStyle,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onCheck.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
    _c(
      "span",
      { staticClass: "text-container" },
      [
        _c("span", {
          staticStyle: { "font-weight": "bold" },
          domProps: { innerHTML: _vm._s(_vm.titleText) },
        }),
        _c("after-tip", {
          attrs: { source: _vm.afterTipData, options: _vm.afterTipOptions },
        }),
      ],
      1
    ),
    _vm.isShowClipData
      ? _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isCleanMode,
              expression: "!isCleanMode",
            },
          ],
          staticClass: "iconfont icon-link1",
          on: {
            click: function ($event) {
              return _vm.showClipData(_vm.source.clip_data)
            },
          },
        })
      : _vm._e(),
    _vm.source.reflaw_num !== undefined &&
    _vm.source.cite_ledge_num !== undefined
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCleanMode,
                expression: "!isCleanMode",
              },
            ],
            staticClass: "right-num dropdown",
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.source.reflaw_num))]),
            _c("span", [_vm._v(" / ")]),
            _c("span", [_vm._v(_vm._s(_vm.source.cite_ledge_num))]),
            _c("div", { staticClass: "dropdown-content" }, [
              _c(
                "p",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showQuote(0)
                    },
                  },
                },
                [
                  _vm._v(
                    "被" + _vm._s(_vm.source.quote_num || 0) + "篇判例引用"
                  ),
                ]
              ),
              _c(
                "p",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showQuote(1)
                    },
                  },
                },
                [
                  _vm._v(
                    " 被" +
                      _vm._s(_vm.source.cite_ledge_num || 0) +
                      "篇知识引用 "
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }