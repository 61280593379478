<template>
  <div>
    <van-tabs :ellipsis="false" @click="onClick" v-model="activeName" border>
      <van-tab v-for="(item, index) in tabsList" :key="index" :title="`${Object.keys(item)[0]}(${Object.values(item)[0] > 1000 ? '999+' : Object.values(item)[0]})`" :name="Object.keys(item)[0]">
        <div>
          <template v-if="activeName === '关联法条' || activeName === '司法解释' || activeName === '探索法'">
            <div v-for="(arr, num) in dataList" class="show" :key="arr.law_title + num" style="padding: 10px 18px">
              <van-collapse v-model="collapseNum" @change="changeCollape(num)" v-if="arr.timeliness && arr.timeliness !== '现行有效'" accordion>
                <van-collapse-item :name="num">
                  <template #title>
                    <div class="hover_p" style="flex: 1">
                      <div>
                        <span style="color: #eb7c8a; margin-right: 10px">{{ arr.timeliness }}</span>
                        {{ arr.law_title }}
                      </div>
                    </div>
                  </template>
                  <template #right-icon>
                    <div v-if="arr.fatiao && arr.fatiao.length" class="jiantouBox">
                      <span>共 {{ arr.fatiao.length }} 条</span>
                      <svg v-if="collapseNum === num" class="jiantou" t="1640927746587" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2115" width="20" height="20">
                        <path
                          d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z"
                          p-id="2116"
                          fill="#ff9900"
                        ></path>
                      </svg>
                      <svg
                        v-else
                        style="transform: rotate(-90deg)"
                        class="jiantou"
                        t="1640927746587"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2115"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z"
                          p-id="2116"
                          fill="#ff9900"
                        ></path>
                      </svg>
                    </div>
                  </template>
                  <template v-if="arr.fatiao">
                    <div>
                      <p class="case_info" v-if="activeName === '司法解释' || activeName === '探索法'">
                        {{ arr.pub_date }}发布 /{{ arr.exc_date }}实施 /
                        {{ arr.potency_child }}
                      </p>
                      <p class="case_info" v-else>
                        {{ arr.publish_machinery }} / {{ arr.pub_date }}发布 / {{ arr.exc_date }}实施 /
                        {{ arr.potency_child }}
                      </p>
                    </div>
                    <div v-for="(child, number) in arr.fatiao" :key="'-c-' + number">
                      <law-addition-text :childItem2="child" :childItem="arr" :item="Object.keys(item)[0]" @copy="copy" @toDetail="toDetail" />
                    </div>
                  </template>
                </van-collapse-item>
              </van-collapse>
              <div v-else>
                <p style="margin-right: 10px" class="title_p hover_p">
                  {{ arr.law_title }}
                </p>
                <div>
                  <p class="case_info" v-if="activeName === '司法解释'">
                    {{ arr.pub_date }}发布 / {{ arr.exc_date }}实施 /
                    {{ arr.potency_child }}
                  </p>
                  <p class="case_info" v-else>
                    {{ arr.publish_machinery }} / {{ arr.pub_date }}发布 / {{ arr.exc_date }}实施 /
                    {{ arr.potency_child }}
                  </p>
                </div>
                <template v-if="arr.fatiao && arr.fatiao.length">
                  <div v-for="(child, number) in arr.fatiao" :key="'-c-' + number">
                    <law-addition-text :childItem2="child" :childItem="arr" :checkedAll="arr.checked" :item="Object.keys(item)[0]" @copy="copy" @toDetail="toDetail" />
                  </div>
                </template>
              </div>
            </div>
            <div class="loadMore" v-if="isShowMore && delayMore">
              <span @click="getMore('law')">加载更多</span>
            </div>
          </template>
          <template v-else-if="activeName === '行政法规' || activeName === '地方法规'">
            <div v-for="(arr, num) in dataList" class="show" :key="arr.law_title + num" style="padding: 10px 18px">
              <van-collapse v-model="collapseNum" @change="changeCollape(num)" v-if="arr.timeliness && arr.timeliness !== '现行有效'" accordion>
                <van-collapse-item :name="num">
                  <template #title>
                    <div class="hover_p" style="flex: 1">
                      <span style="color: #eb7c8a; margin-right: 10px">{{ arr.timeliness }}</span>
                      {{ arr.law_title }}
                      <span class="copy-div">
                        <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click.stop="copy(arr.law_title)"></i>
                        <i class="iconfont icon-send copy-icon" @click.stop="toDetail(arr)"></i>
                      </span>
                    </div>
                  </template>
                  <template #right-icon>
                    <div class="jiantouBox" v-if="arr.fatiao && arr.fatiao.length">
                      <span>共 {{ arr.fatiao.length }} 条</span>
                      <svg v-if="collapseNum === num" class="jiantou" t="1640927746587" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2115" width="20" height="20">
                        <path
                          d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z"
                          p-id="2116"
                          fill="#ff9900"
                        ></path>
                      </svg>
                      <svg
                        v-else
                        style="transform: rotate(-90deg)"
                        class="jiantou"
                        t="1640927746587"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2115"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z"
                          p-id="2116"
                          fill="#ff9900"
                        ></path>
                      </svg>
                    </div>
                  </template>
                  <template v-if="arr.fatiao && arr.fatiao.length">
                    <div>
                      <p class="case_info">{{ arr.publish_machinery }} / {{ arr.pub_date }}发布 / {{ arr.exc_date }}实施 / {{ arr.potency_child }}</p>
                    </div>
                    <div v-for="(val, idx) in arr.fatiao" :key="idx">
                      <div class="hover_p" style="font-family: Microsoft Yahei; font-size: 24px; font-weight: bold; color: #333">
                        {{ val.title }}
                        <span class="copy-div">
                          <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.law_title)"></i>
                          <i class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                        </span>
                      </div>
                      <div class="hover_p">
                        {{ val.text }}
                        <span class="copy-div">
                          <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.law_title)"></i>
                          <i class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                        </span>
                      </div>
                    </div>
                  </template>
                </van-collapse-item>
              </van-collapse>
              <div v-else>
                <p style="margin-right: 10px" class="title_p hover_p">
                  {{ arr.law_title }}

                  <span class="copy-div">
                    <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.law_title)"></i>
                    <i class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                  </span>
                </p>
                <div>
                  <p class="case_info">{{ arr.publish_machinery }} / {{ arr.pub_date }}发布 / {{ arr.exc_date }}实施 / {{ arr.potency_child }}</p>
                </div>
                <template v-if="arr.fatiao && arr.fatiao.length">
                  <div v-for="(val, idx) in arr.fatiao" :key="idx">
                    <div class="hover_p" style="font-family: Microsoft Yahei; font-size: 14px; font-weight: bold; color: #333">
                      {{ val.title }}
                      <span class="copy-div">
                        <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.law_title)"></i>
                        <i class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                      </span>
                    </div>
                    <div class="hover_p">
                      {{ val.text }}
                      <span class="copy-div">
                        <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.law_title)"></i>
                        <i class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="loadMore" v-if="isShowMore && delayMore">
              <span @click="getMore('law')">加载更多</span>
            </div>
          </template>
          <template v-else-if="activeName === '立法解读' && lifajieduList.length">
            <div class="show" style="padding: 20px 40px">
              <div>
                <div class="lifajiedu_title">条文主旨</div>
                <div style="text-indent: 40px; padding: 10px 0">
                  {{ lifajieduList[3] }}
                </div>
              </div>
              <div>
                <div class="lifajiedu_title">条文解读</div>
                <div style="text-indent: 40px; padding: 10px 0">
                  {{ lifajieduList.slice(5).join('') }}
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="activeName === '法条沿革' && fatiaoyangeList.length">
            <div class="show" style="padding: 10px 18px">
              <div class="top_left_img_box">
                <img src="@/assets/top-left.png" alt="" />
                <div class="img_text">
                  {{ fatiaoyangeList[1].type }}
                </div>
              </div>
              <div>
                <van-steps direction="vertical" active-color="#f7b552" active="0" active-icon="circle">
                  <van-step v-for="(ite, idx) in fatiaoyangeList" :key="idx">
                    <h3>{{ ite.law_title }}</h3>
                    <p>{{ ite.text }}</p>
                    <p class="step_time">2016-07-11</p>
                  </van-step>
                </van-steps>
              </div>
            </div>
          </template>
          <template v-else-if="activeName === '新旧对照' && xinjiuduizhaoList.length">
            <div class="show" style="padding: 10px 18px">
              <div class="top_left_img_box">
                <img src="@/assets/top-left.png" alt="" />
                <div class="img_text">
                  {{ xinjiuduizhaoList[0]?.type }}
                </div>
              </div>
              <div class="newAndOld">
                <div class="tip_box">
                  <div class="tip_text_bl">保留文字</div>
                  <div class="tip_text_sc">删除文字</div>
                  <div class="tip_text_xz">新增文字</div>
                </div>
                <div class="new_and_old_content">
                  <div class="new_and_old_content_time">
                    {{ xinjiuduizhaoList[0].publish_date }}
                  </div>
                  <div style="flex: 1">
                    <div class="new_and_old_content_title">
                      {{ xinjiuduizhaoList[0].law_title }}
                    </div>
                    <div>{{ xinjiuduizhaoList[0].text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-for="(arr, num) in dataList" class="show" :key="num" style="padding: 10px 18px 0">
              <p style="margin-right: 10px" class="title_p hover_p">
                {{ arr.case_name || arr.Title || arr.title }}

                <span class="copy-div">
                  <i class="iconfont icon-fuzhi1 copy-icon" title="复制本段内容" @click="copy(arr.case_name || arr.Title || arr.title)"></i>
                  <i v-if="activeName !== '处罚文书'" class="iconfont icon-send copy-icon" @click="toDetail(arr)"></i>
                </span>
              </p>
              <div>
                <p class="case_info" v-if="activeName === '知识引用'">{{ arr.publisher }} / {{ arr.pub_date }}</p>
                <p class="case_info" v-else>
                  {{ arr.court_name || arr.LawDepartment }} / {{ arr.case_id || arr.DocumentNO }} /
                  {{ arr.judge_date || arr.PunishmentDate || arr.pub_date }}
                </p>
              </div>
            </div>
            <div class="loadMore" v-if="isShowMore && delayMore">
              <span @click="getMore('case')">加载更多</span>
            </div>
          </template>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LawAdditionText from './law-addition-text.vue'

export default {
  props: {
    source: {
      type: Object,
      default: (_) => {},
    },
  },
  components: {
    LawAdditionText,
  },
  data() {
    return {
      activeName: null,
      dataList: [],
      collapseNum: null,
      isShowList: [],
      isShowMore: false,
      delayMore: false,
      scrollId: '',
      casePage: 1,
      pageSize: 10,
      lifajieduList: [],
      fatiaoyangeList: [],
      xinjiuduizhaoList: [],
      showTips: false,
    }
  },
  computed: {
    ...mapState('searchReport', ['lawId']),
    tabsList({ source }) {
      if (source.law_relevancy && source.law_relevancy.length > 0) {
        return source.law_relevancy
      } else {
        return []
      }
    },
    lawNum({ source }) {
      const pattern = /^[^【]*/
      const name = pattern.exec(source.name)[0]
      return name
    },
  },
  methods: {
    getDataList(e) {
      this.$axios.get(`${this.$pydataBase}/api/nvi/law/detailExtendRelevancy?law_id=${this.lawId}&category=${e}&name=${this.lawNum}&page_no=1&page_size=${this.pageSize}`).then((res) => {
        if (res.data.code === 200 || res.data.code === '200') {
          this.dataList = res.data.data.data
          this.delayMore = true
        }
      })
    },
    getCaseDataList(e, flag = false) {
      if (e === '权威判例' || e === '检察文书' || e === '最高院判例' || e === '高院判例' || e === '中院判例' || e === '基层判例') {
        const url = flag
          ? `${this.$pydataBase}/api/nvi/law/detailExtendPrecedentRelevancy?law_id=${this.lawId}&scroll_id=${this.scrollId}`
          : `${this.$pydataBase}/api/nvi/law/detailExtendPrecedentRelevancy?law_id=${this.lawId}&court_level=${e}&name=${this.lawNum}`
        this.$axios.get(url).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            console.log('data', res)
            if (flag) {
              this.dataList.push(...res.data.data.data)
            } else {
              this.dataList = res.data.data.data
            }
            this.delayMore = true
            this.scrollId = res.data.data.scroll_id
          }
        })
      } else if (e === '处罚文书') {
        const url = flag
          ? `${this.$pydataBase}/api/nvi/law/detailExtendXingzhengchufaRelevancy?law_id=${this.lawId}&scroll_id=${this.scrollId}`
          : `${this.$pydataBase}/api/nvi/law/detailExtendXingzhengchufaRelevancy?law_id=${this.lawId}&name=${this.lawNum}`
        this.$axios.get(url).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            if (flag) {
              this.dataList.push(...res.data.data.data)
            } else {
              this.dataList = res.data.data.data
            }
            this.delayMore = true
            this.scrollId = res.data.data.scroll_id
          }
        })
      } else if (e === '法条沿革') {
        this.$axios.get(`${this.$pydataBase}/api/nvi/law/detailExtendFatiaoyangeRelevancy?law_id=${this.lawId}&name=${this.lawNum}`).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            this.fatiaoyangeList = res.data.data[0].ygxx_list
            this.delayMore = true
          }
        })
      } else if (e === '知识引用') {
        const url = flag
          ? `${this.$pydataBase}/api/nvi/law/detailExtendZhishiyinyongRelevancy?law_id=${this.lawId}&scroll_id=${this.scrollId}`
          : `${this.$pydataBase}/api/nvi/law/detailExtendZhishiyinyongRelevancy?law_id=${this.lawId}&name=${this.lawNum}`
        this.$axios.get(url).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            if (flag) {
              this.dataList.push(...res.data.data.data)
            } else {
              this.dataList = res.data.data.data
            }
            this.delayMore = true
            this.scrollId = res.data.data.scroll_id
          }
        })
      } else if (e === '立法解读') {
        this.$axios.get(`${this.$pydataBase}/api/nvi/law/detailExtendFatiaoshiyiRelevancy?law_id=${this.lawId}&name=${this.lawNum}`).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            this.lifajieduList = res.data.data[0].fatiaoshiyi_list[0].sy_content
            this.delayMore = true
          }
        })
      } else if (e === '探索法') {
        this.$axios.get(`${this.$pydataBase}/api/nvi/law/detailExtendRelevancy?law_id=${this.lawId}&name=${this.lawNum}&category=探索法&page_no=1&page_size=${this.pageSize}`).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            if (flag) {
              this.dataList.push(...res.data.data.data)
            } else {
              this.dataList = res.data.data.data
            }
            this.delayMore = true
          }
        })
      } else if (e === '新旧对照') {
        this.$axios.get(`${this.$pydataBase}/api/nvi/law/detailExtendXinjiuduizhaoRelevancy?law_id=${this.lawId}&name=${this.lawNum}`).then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            this.xinjiuduizhaoList = res.data.data[0].data
            this.delayMore = true
          }
        })
      }
    },
    onClick(e) {
      this.pageSize = 10
      this.casePage = 1
      this.delayMore = false
      if (e === '关联法条' || e === '司法解释' || e === '行政法规' || e === '地方法规') {
        this.getDataList(e)
      } else if (e === '权威判例' || e === '检察文书') {
        this.getCaseDataList(e)
      } else if (e === '处罚文书' || e === '知识引用' || e === '立法解读' || e === '新旧对照' || e === '法条沿革') {
        this.getCaseDataList(e)
      } else if (e === '最高院判例' || e === '高院判例' || e === '中院判例' || e === '基层判例') {
        this.getCaseDataList(e)
      } else if (e === '探索法') {
        this.getCaseDataList(e)
      }

      this.activeName = e
      this.isShowMore = this.tabsList.find((item) => Object.keys(item)[0] === e)[e] > this.pageSize
    },
    // 加载更多
    getMore(val) {
      const arr = this.tabsList.find((item) => Object.keys(item)[0] === this.activeName)
      this.pageSize += 10
      if (val === 'law') {
        this.getDataList(this.activeName)
      } else {
        this.casePage++
        this.getCaseDataList(this.activeName, true)
      }
      val === 'law' ? (this.isShowMore = Object.values(arr)[0] > this.pageSize) : (this.isShowMore = Object.values(arr)[0] > this.casePage * 10)
    },
    changeCollape(index) {
      this.collapseNum = index
      // this.isShowList[index] = !this.isShowList[index]
    },
    copyBlockOne(childItem, childItem2) {
      let str = ''
      str += childItem.law_title + '\n'
      str += childItem2.title + '\n'
      str += childItem2.text + '\n'
      this.copy(str)
    },
    // 复制事件
    copy(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({ type: 'success', message: '内容已复制' })
    },
    // 详情
    toDetail(item, title = '') {
      console.log('toDetail', item, document.location.origin)
      // this.$router.push(`/search/law/${item.law_id}?toCatalog=${item.law_title}`)
      if (this.activeName === '知识引用') {
        return window.open(item.doc)
      }
      if (item.doc) {
        // 跳转打开判例详情
        let isReplace = 'false'
        if (item.doc.includes('/')) {
          item.doc = item.doc.replaceAll('/', '-')
          isReplace = 'true'
        }
        item.doc = encodeURIComponent(item.doc)
        // const url = `https://mobile.farbun.com/search?docid=${item.doc}&isReplace=${isReplace}`
        // window.open(url)
        // this.$router.push(`/search?docid=${item.doc}&isReplace=${isReplace}`)
        const payload = {
          id: item.doc,
          type: 'doc',
          title: item.case_name,
          isReplace: isReplace,
        }
        this.$bus.$emit('openDetail', payload)
      } else if (item.law_id) {
        if (title) {
          // 跳转打开法律详情,滚动到对应的条目
          // const url = `https://mobile.farbun.com/search/law/${item.law_id}?toCatalog=${title}`
          // window.open(url)
          this.$router.push(`/search/law/${item.law_id}?toCatalog=${item.law_title}`)
        } else {
          // 跳转打开法律详情
          // const url = `https://mobile.farbun.com/search/law/${item.law_id}`
          // window.open(url)
          this.$router.push(`/search/law/${item.law_id}`)
        }
      } else {
        // 既没有doc又没有law_id的，表示是当前法律
        if (item.title) {
          // 滚动到当前条目
          this.broadcast('law-scroller', 'on-catalog-select', {
            name: item.title,
            type: '条',
          })
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
::v-deep .van-cell
  padding 0
  background #fdfbf8

.title_p
  font-size 15px

.case_info
  font-size 12px
  color #999
::v-deep .van-collapse-item__content{
  padding 0
}

::v-deep .van-tab--active
  color #1890ff
  border 1px solid #1890ff
  border-bottom 1px solid #fff
  border-radius 5px 5px 0 0
  background-color #fff

::v-deep .van-tab__text
  font-size 12px

::v-deep .van-tabs__line
  width 0
  height 0
  background-color transparent

::v-deep .van-tabs__content
  border 1px solid #1890ff

.tab_active
  // background #eb7c8a!important

::v-deep .van-tabs--line .van-tabs__wrap
  height 30px

::v-deep  .van-tabs__wrap--scrollable .van-tab
  padding 0 3px!important

::v-deep .van-steps--vertical
  padding-left 110px
  margin-top 10px
  background-color #fdfbf8
::v-deep .van-step__title
  position relative
  .step_time
    position absolute
    left -100px
    top 0
    font-size 13px
    color #999
.lifajiedu_title
  font-size 16px
  font-weight 600
  color #000

.newAndOld
  position relative
  padding-left 10px
  .tip_text_bl,
  .tip_text_sc,
  .tip_text_xz
    margin-right 5px

.tip_box
  position absolute
  right 5px
  top 0px
  display flex
  /* justify-content: center; */
  align-items center

.tip_text_bl::before
  content ''
  display inline-block
  width 10px
  height 10px
  background-color #737373
  margin-right 4px

.tip_text_sc::before
  content ''
  display: inline-block
  width 10px
  height 10px
  background-color #d0011c
  margin-right 4px

.tip_text_xz::before
  content ''
  display inline-block
  width 10px
  height 10px
  background-color #009cff
  margin-right 4px

.new_and_old_content
  display flex
  align-items flex-start
  margin-bottom 10px
  padding-top 19px
  color #767575

.new_and_old_content_time
  color #9e9e9e
  padding-right 10px
  font-size 13px

.new_and_old_content_title
  color #414141
  margin-bottom 10px

.copy-div
  visibility: hidden;
  i
    color rgb(86, 154, 249)
    cursor pointer
    margin-left 10px
    font-size 14px

.copy-div :hover
  visibility: visible;

.hover_p:hover .copy-div
  visibility: visible;

.show
  background-color #fdfbf8
  margin-top 1px
  position relative

  .top_left_img_box
    position absolute
    top 0
    left -1px
    .img_text
      position absolute
      top 20%
      left -1px
      transform rotate(-51deg)
      width 100%
      font-size 10px
      color #fff
.loadMore
  background #fdfbf8
  height 40px
  margin 0 18px
  line-height 40px
  text-align center
  span
    color #FE981F
    border-bottom: 1px solid #FE981F;
    display inline-block
    line-height: 20px
</style>
