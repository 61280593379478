export default {
  computed: {
    // 单选框样式
    checkBoxClassName() {
      return this.checked ? 'iconfont icon-checked' : 'iconfont icon-unchecked'
    },
    // 简洁模式显示
    cleanModeVisibleStyle() {
      if (this.isCleanMode) {
        return { visibility: 'hidden' }
      }
      return { visibility: 'visible' }
    }
  }
}
