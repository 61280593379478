<template>
  <div class="inner-query">
    <div class="input-field">
      <van-search
        v-model.trim="input"
        ref="inputRef"
        @keyup.native="hanldeKey('SPACE')"
        @keydown.enter.native="handleKey('ENTER')"
        placeholder="请输入搜索关键词"
        show-action
        shape="round"
      >
        <div slot="action">
          <span @click="addKw">查找</span>
        </div>
      </van-search>
    </div>
    <div class="content">
      <div class="kw-item" v-for="kw in kwList" :key="kw">
        <div class="name">{{ kw }}</div>
        <div class="status">共 {{ calcResultByKw(kw) }} 条</div>
        <div class="delete" @click="deleteMe(kw)">
          <i class="iconfont icon-delete"></i>
        </div>
      </div>
    </div>
    <div class="actions">
      <van-button size="normal" @click="cancel">退出</van-button>
      <van-button size="normal" class="flex-1" type="info" @click="viewResult"
        >查看</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'inner-query',
  props: {
    // 高亮结果
    resultList: {
      type: Array,
      default: _ => []
    }
  },
  data() {
    return {
      input: '',
      kwList: []
    }
  },
  computed: {},
  methods: {
    // 添加关键词
    addKw() {
      if (this.input) {
        this.kwList = [...new Set([...this.kwList, this.input.trim()])]
        this.input = ''
      }
      if (this.kwList.length) {
        this.$emit('highlight', this.kwList)
      }
    },
    // 键盘处理
    hanldeKey(key) {
      this.addKw()
    },
    viewResult() {
      this.$emit('view', this.kwList)
    },
    cancel() {
      this.$emit('cancel')
    },
    deleteMe(kw) {
      this.kwList = this.kwList.filter(k => k !== kw)
    },
    // 计算单个关键词的高亮总数
    calcResultByKw(kw) {
      const r = this.resultList.filter(item => item.kw === kw)
      return (r && r.length) || 0
    },
    // 输入框聚焦
    focus() {
      if (this.kwList.length === 0) {
        const el = this.$refs.inputRef && this.$refs.inputRef
        try {
          const input = el.getElementsByClassName('van-field__control')[0]
          input.focus()
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  mounted() {},
  created() {}
}
</script>

<style scoped lang="stylus">
.inner-query
  display flex
  flex-direction column
  width 100vw
  height 100%
  & .input-field
    height 44px
  & .actions
    margin-bottom 4px
    height 44px
    display flex
    justify-content space-evenly
    padding 0 5px
    & .flex-1
      flex 1
      margin-left 5px
  & .content
    flex 1
    margin 20px 0
    overflow-y auto
    & .kw-item
      display flex
      padding 0 20px
      font-size 16px
      height 40px
      align-items center
      justify-content space-between
      & .status
        color #777
      & .delete
        color #999
</style>
